import { Edit as EditIcon } from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addComment, fetchComments, updateComment } from '../../redux/commentSlice';

const Comment = ({ commentData }) => {
    const dispatch = useDispatch();
    const comments = useSelector(state => state.comments.comments);
    const status = useSelector(state => state.comments.status);
    
    // Match the passed commentData with the one from the Redux store
    const existingComment = comments.find(comment => comment.REPORT === commentData?.REPORT) || commentData;

    const [newComment, setNewComment] = useState({
        REPORT: existingComment?.REPORT || '',
        COMMENTS_1: existingComment?.COMMENTS_1 || '',
        COMMENTS_2: existingComment?.COMMENTS_2 || '',
        NETADD_TARGET: existingComment?.NETADD_TARGET || '',
        ARR_TARGET: existingComment?.ARR_TARGET || '',
        MONTH: existingComment?.MONTH || '',
    });

    const [formVisible, setFormVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        dispatch(fetchComments());
    }, [dispatch]); 

    useEffect(() => {
        if (status === 'succeeded') {
            resetForm();
        }
    }, [status]); // Reset form when comments are successfully fetched

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewComment((prevComment) => ({
            ...prevComment,
            [name]: value,
        }));
    };

    const handleMonthChange = (e) => {
        setNewComment((prevComment) => ({
            ...prevComment,
            MONTH: e.target.value,
        }));
    };

    const toggleFormVisibility = () => {
        setFormVisible(!formVisible);
        if (!formVisible && editMode) {
            setNewComment({
                REPORT: existingComment?.REPORT || '',
                COMMENTS_1: '',
                COMMENTS_2: '',
                NETADD_TARGET: '',
                ARR_TARGET: '',
                MONTH: '',
            });
            setEditMode(false);
        }
    };

    const submitComment = async () => {
        try {
            if (editMode) {
                await dispatch(updateComment(newComment)).unwrap();
            } else {
                await dispatch(addComment(newComment)).unwrap();
            }
            dispatch(fetchComments()); // Refresh comments list
        } catch (error) {
            console.error("Failed to submit comment: ", error);
        }
    };

    const resetForm = () => {
        setNewComment({
            REPORT: existingComment?.REPORT || '',
            COMMENTS_1: '',
            COMMENTS_2: '',
            NETADD_TARGET: '',
            ARR_TARGET: '',
            MONTH: '',
        });
        setFormVisible(false);
        setEditMode(false);
    };

    const handleEditClick = () => {
        setEditMode(true);
        setFormVisible(true);
        setNewComment({
            REPORT: existingComment?.REPORT || '',
            COMMENTS_1: existingComment?.COMMENTS_1 || '',
            COMMENTS_2: existingComment?.COMMENTS_2 || '',
            NETADD_TARGET: existingComment?.NETADD_TARGET || '',
            ARR_TARGET: existingComment?.ARR_TARGET || '',
            MONTH: existingComment?.MONTH || '',
        });
    };

    return (
        <Card className="comment-card">
            <CardContent>
                <Box className="comment-container">
                    <Typography variant="h6" className="comment-report">
                        {existingComment?.REPORT}
                    </Typography>
                    <Typography variant="body1" className="comment-text">
                        {existingComment?.COMMENTS_1}
                    </Typography>

                    <Box mt={2}>
                        <Button
                            variant="contained"
                            className="comment-button"
                            style={{ backgroundColor: '#382c2c', color: 'white' }}
                            onClick={toggleFormVisibility}
                        >
                            {formVisible ? 'Cancel' : 'Add Comment'}
                        </Button>
                        <IconButton
                            onClick={handleEditClick}
                            color="primary"
                            aria-label="edit comment"
                            style={{ marginLeft: '10px' }}
                        >
                            <EditIcon />
                        </IconButton>
                    </Box>

                    {formVisible && (
                        <Box mt={2} style={{ maxHeight: '100px', overflowY: 'auto', padding: '10px' }}>
                            <Typography variant="h6" style={{ marginBottom: '8px' }}>
                                {editMode ? 'Edit Comment' : 'Add a New Comment'}
                            </Typography>
                            <form noValidate autoComplete="off">
                                <TextField
                                    label="Comments 1"
                                    name="COMMENTS_1"
                                    value={newComment.COMMENTS_1}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    required
                                />
                                <TextField
                                    label="Comments 2"
                                    name="COMMENTS_2"
                                    value={newComment.COMMENTS_2}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                />
                                <TextField
                                    label="Net Add Target"
                                    name="NETADD_TARGET"
                                    type="number"
                                    value={newComment.NETADD_TARGET}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    required
                                />
                                <TextField
                                    label="ARR Target"
                                    name="ARR_TARGET"
                                    type="number"
                                    value={newComment.ARR_TARGET}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    required
                                />
                                <FormControl fullWidth margin="dense" size="small">
                                    <InputLabel>Month</InputLabel>
                                    <Select
                                        label="Month"
                                        name="MONTH"
                                        value={newComment.MONTH}
                                        onChange={handleMonthChange}
                                        required
                                    >
                                        {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month) => (
                                            <MenuItem key={month} value={month}>
                                                {month}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button
                                    variant="contained"
                                    className="comment-button"
                                    style={{ backgroundColor: '#382c2c', color: 'white', marginTop: '8px' }}
                                    onClick={submitComment}
                                >
                                    {editMode ? 'Update Comment' : 'Submit Comment'}
                                </Button>
                            </form>
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default Comment;
