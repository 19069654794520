import axios from 'axios';
import config from './../../../config';

const deleteUserRoleByIdApi = async (row) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const result = await axios.delete(`${config.apiURL}/v2/deleteUserRole`, {
			headers: {
				Authorization: `Bearer ${token}`,
				row: JSON.stringify(row),
			},
		});
		return result;
	} catch (err) {
		return err;
	}
};

export {deleteUserRoleByIdApi};
