import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Header from '../../components/Header/Header';
import {useSelector, useDispatch} from 'react-redux';
import {addReports, addDefaultReport} from '../../redux/userReducer';
import CustomNav from '../../components/NavBar/CustomNav';
import {checkvalidEmbedToken} from '../../components/Utilities/checkValidToken';
import {useOutletContext} from 'react-router-dom';
import {getUserReportsGroupByOrgName} from '../../components/Utilities/v2Apis/getUserReports';

const Home = () => {
	const user = useSelector((state) => state.PowerBi.user);
	const reports = useSelector((state) => state.PowerBi.reports);
	const defaultReport = useSelector((state) => state.PowerBi.defaultReport);
	const googleIdToken = useOutletContext(); // <-- access context value
	const [reportsArray, setReportsArray] = useState([]);
	const [isloadingReports, setLoadingReports] = useState(true);
	const dispatch = useDispatch();

	//UseEffect to get the reports to show in the homescreen
	useEffect(() => {
		if (checkvalidEmbedToken(googleIdToken)) {
			getUserReportsGroupByOrgName(user?.ID).then((result) => {
				console.log(result, 'result');
				dispatch(addReports(result));
				setLoadingReports(false);
			});
		}
	}, [dispatch, googleIdToken, user]);
	//Setting the Default Report Early for the Header Refresh Date Usage,it checks the reports after the loading completed
	//and then check if default report exists or else it will set the first report as default report
	useEffect(() => {
		if (
			!isloadingReports &&
			reports.length === 2 &&
			Object.keys(reports[0]).length !== 0 &&
			reports[0].constructor === Object
		) {
			setReportsArray(Object.entries(reports[0]));
			dispatch(
				addDefaultReport(
					reports.length > 0 && reports[1].length !== 0
						? reports[1][0]
						: Object.entries(reports[0]).length >= 1
						? Object.entries(reports[0])[0][1][0]
						: {}
				)
			);
		}
	}, [defaultReport, dispatch, isloadingReports, reports]);

	return (
		<Box sx={{display: 'flex'}}>
			<Header />
			{isloadingReports ? (
				<CircularProgress
					style={{color: '#f7901d', position: 'fixed', top: '50%', left: '50%'}}
				/>
			) : (
				<CustomNav reportsArray={reportsArray} />
			)}
		</Box>
	);
};

export default Home;
