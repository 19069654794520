import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import classes from './RevenueForm.module.css';

function RevenueTable({data, handleClose}) {
	const [futureData, setFutureData] = useState({});

	const getLast12Months = () => {
		const months = [];
		let currentDate = new Date();
		for (let i = 0; i < 12; i++) {
			months.push(currentDate.toLocaleString('default', {month: 'short'}));
			currentDate.setMonth(currentDate.getMonth() - 1);
		}
		return months.reverse();
	};

	const last12Months = getLast12Months();

	const getNextThreeMonths = () => {
		const months = [];
		let currentDate = new Date();
		for (let i = 1; i <= 3; i++) {
			currentDate.setMonth(currentDate.getMonth() + 1);
			months.push(currentDate.toLocaleString('default', {month: 'short'}));
		}
		return months;
	};

	const nextThreeMonths = getNextThreeMonths();

	const getMonthIndex = (refDate) => {
		const currentDate = new Date();
		const diffInMonths =
			(currentDate.getFullYear() - refDate.getFullYear()) * 12 +
			(currentDate.getMonth() - refDate.getMonth());
		return 11 - diffInMonths;
	};

	const groupData = Object.keys(data).map((groupKey) => {
		const groupItems = data?.[groupKey];
		const revenueByMonth = new Array(12).fill(null);

		groupItems?.forEach((item) => {
			const refDate = new Date(item.Ref_Dt);
			const monthIndex = getMonthIndex(refDate);
			if (monthIndex >= 0 && monthIndex < 12) {
				revenueByMonth[monthIndex] = Math.ceil(item?.total_revenue);
			}
		});

		return {
			group: groupKey,
			revenueByMonth,
		};
	});

	const handleNextMonthChange = (groupIndex, futureIndex, value) => {
		setFutureData((prev) => {
			const updated = {...prev};
			if (!updated[groupIndex]) {
				updated[groupIndex] = {};
			}
			updated[groupIndex][futureIndex] = value;
			return updated;
		});
	};

	return (
		<div
			style={{
				backgroundColor: 'white',
				overflow: 'auto',
			}}
			className={classes.WindowSize}
		>
			<div style={{display: 'flex'}}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'left',
						width: '50%',
					}}
				>
					<Typography style={{fontWeight: 'bold'}}>
						Revenue Data for the Last 12 Months
					</Typography>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'right',
						width: '50%',
						alignItems: 'center',
					}}
				>
					<CloseIcon
						onClick={handleClose}
						className={classes.closeButton}
					/>
				</div>
			</div>
			<table
				border='1'
				style={{
					width: '100%',
					borderCollapse: 'collapse',
					tableLayout: 'fixed',
				}}
				className={classes.Table}
			>
				<thead>
					<tr>
						<th style={{padding: '2px', textAlign: 'center'}}>
							<Typography style={{fontSize: '0.6rem'}}>Group</Typography>
						</th>
						{last12Months.map((month, index) => (
							<th
								key={index}
								style={{padding: '2px', textAlign: 'center'}}
							>
								<Typography style={{fontSize: '0.6rem'}}>{month}</Typography>
							</th>
						))}
						{nextThreeMonths.map((month, index) => (
							<th
								key={index}
								style={{padding: '2px', textAlign: 'center'}}
							>
								<Typography style={{fontSize: '0.6rem'}}>{month}</Typography>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{groupData.map((group, groupIndex) => (
						<tr key={groupIndex}>
							<td
								style={{
									padding: '2px',
									fontSize: '0.6rem',
									textAlign: 'center',
								}}
							>
								{group?.group}
							</td>
							{group?.revenueByMonth.map((revenue, monthIndex) => (
								<td
									key={monthIndex}
									style={{
										padding: '2px',
										fontSize: '0.6rem',
										textAlign: 'right',
									}}
								>
									{revenue !== null ? revenue : 'null'}
								</td>
							))}
							{[...Array(3)].map((_, futureIndex) => (
								<td
									key={futureIndex}
									style={{
										padding: '2px',
										fontSize: '0.6rem',
										textAlign: 'center',
									}}
								>
									<input
										type='text'
										// placeholder='Add data'
										style={{
											width: '30px',
											fontSize: '0.6rem',
										}}
										onChange={(e) =>
											handleNextMonthChange(
												groupIndex,
												futureIndex,
												e.target.value
											)
										}
									/>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

const RevenueForm = (props) => {
	const {handleClose} = props;
	const RevenueFormData = useSelector((state) => state.Form.RevenueFormData);
	return (
		<RevenueTable
			data={RevenueFormData}
			handleClose={handleClose}
		/>
	);
};

export default RevenueForm;
