import {Grid, Box} from '@mui/material';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import CustomAlert from '../../../components/Alerts/CustomAlert';
import MultiSelectComponentUserReport from '../MultiSelectComponentUserReports/MultiSelectComponentUserReport';

const AddReportToUser = (props) => {
	const {userId} = props;
	const [alert, setAlert] = useState(false);
	const [alertmessage, setAlertMessage] = useState(null);
	const [success, setSuccess] = useState(false);
	const userReports = useSelector(
		(state) => state.AdminPageData.reportsForUser
	);

	const handleAlert = (res) => {
		if (res.status === 200) {
			setAlertMessage(res.data.message);
			setAlert(true);
			setSuccess(true);
		} else {
			setAlertMessage(res.response.data.message);
			setAlert(true);
			setSuccess(false);
		}
	};

	const handleAlertClose = () => {
		setAlert(false);
	};

	return (
		<Grid container>
			<Box
				sx={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: 'inherit',
					zIndex: 9999,
				}}
			>
				<CustomAlert
					alert={alert}
					message={alertmessage}
					handleClose={handleAlertClose}
					success={success}
				/>
			</Box>
			<Grid container>
				<Grid
					item
					xs={12}
					md={12}
					xl={12}
				>
					<MultiSelectComponentUserReport
						userReports={userReports}
						handleAlert={handleAlert}
						userId={userId}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default AddReportToUser;
