import {Button} from '@mui/material';
import React, {forwardRef} from 'react';
import classes from './floatDivStyles.module.css';
import PrintIcon from '@mui/icons-material/Print';

const PrintReportBI = forwardRef((props, ref) => {
	const printReport = () => {
		const report = ref.current?.embed; // Access the embedded report instance
		if (report) {
			report.print();
		} else {
			console.error('Report instance is not available.');
		}
	};
	return (
		<Button
			className={classes.floatPrint}
			onClick={printReport}
			variant='contained'
			aria-label='print'
			endIcon={<PrintIcon />}
			sx={{
				backgroundColor: '#f7901d',
				color: '#ffffff',
				border: '2px solid #ffffff',
				borderRadius: '30px',
				'&:hover': {
					backgroundColor: '#f7901d',
					borderColor: '#ffffff',
				},
				textAlign: 'center',
			}}
		>
			Print
		</Button>
	);
});

export default PrintReportBI;
