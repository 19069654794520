import {
  Download,
  Logout,
  ToggleOff,
  ToggleOn,
  UploadFile,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import paperTexture from '../../components/Assets/texture.jpg';
import Logo from './logoImage';

const NavBar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useSelector((state) => state.PowerBi.user);
  const PMS_ROLE = user?.PMS_ACCESS;

  const [isShown, setIsShown] = useState(() => {
    return localStorage.getItem('toggleStatus') === 'show';
  });

  useEffect(() => {
    localStorage.setItem('toggleStatus', isShown ? 'show' : 'hide');
  }, [isShown]);

  const handleToggleClick = () => {
    setIsShown((prev) => !prev); // Toggle the state
  };

  const handleLogout = () => {
    navigate('/'); // Redirect to the main page or login page
  };

  const handleUploadPage = () => {
    navigate('/upload'); // Redirect to the upload page
  };

  const handleDownloadPage = () => {
    navigate('/download'); // Redirect to the download page
  };

  return (
    <AppBar
      position='static'
      color='transparent'
      elevation={0}
      sx={{
        backgroundColor: 'white',
        backgroundImage: `url(${paperTexture})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '70px',
        transition: 'box-shadow 0.3s ease, background-color 0.3s ease',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Initial subtle shadow
        '&:hover': {
          boxShadow: '0 15px 35px rgba(0, 0, 0, 0.4)', // Darker and more pronounced shadow on hover
          backgroundColor: theme.palette.grey[100], // Subtle background color change on hover
        },
      }}
    >
      <Toolbar sx={{minHeight: 150}}>
        <Grid container alignItems='center'>
          {/* Logo with bounce animation */}
          <Typography
            variant='h6'
            color='inherit'
            sx={{
              animation: 'bounce 1s ease-in-out infinite', // Bounce animation for logo
            }}
          >
            <Logo />
          </Typography>
          <Box sx={{flexGrow: 1}} /> {/* To push icons to the right */}
          {/* Icons Area */}
          <Grid
            item
            xs={isMobile ? 6 : 3}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {transform: 'scale(1.1)'}, // Hover scale effect
            }}
          >
            {/* Upload Icon with glowing effect */}
            {(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee') && (
              <IconButton
                color='inherit'
                sx={{
                  p: 1,
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 0 15px rgba(255, 105, 135, 0.8)', // Glow effect
                    transform: 'scale(1.1)',
                  },
                }}
                onClick={handleUploadPage}
              >
                <UploadFile />
              </IconButton>
            )}

            {/* Download Icon with glowing effect */}
            {(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee') && (
              <IconButton
                color='inherit'
                sx={{
                  p: 1,
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 0 15px rgba(255, 105, 135, 0.8)', // Glow effect
                    transform: 'scale(1.1)',
                  },
                }}
                onClick={handleDownloadPage}
              >
                <Download />
              </IconButton>
            )}

            {/* Toggle Button with color change animation */}
            {(PMS_ROLE === 'vp' ||
              PMS_ROLE === 'hr' ||
              PMS_ROLE === 'vp-' ||
              PMS_ROLE === 'vp+' ||
              PMS_ROLE === 'review_committee') && (
              <IconButton
                color='inherit'
                sx={{
                  p: 1,
                  transition:
                    'transform 0.3s ease, color 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    color: theme.palette.primary.main, // Color change on hover
                    transform: 'scale(1.1)',
                    boxShadow: '0 0 15px rgba(0, 204, 255, 0.7)', // Glow effect on hover
                  },
                }}
                onClick={handleToggleClick}
              >
                {isShown ? <ToggleOn /> : <ToggleOff />}
              </IconButton>
            )}

            {/* Logout Icon with glowing effect */}
            <IconButton
              color='inherit'
              sx={{
                p: 1,
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  boxShadow: '0 0 15px rgba(255, 105, 135, 0.8)', // Glow effect
                  transform: 'scale(1.1)',
                },
              }}
              onClick={handleLogout}
            >
              <Logout />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
