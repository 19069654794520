import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config';

// Initial State
const initialState = {
  incrementData: [], // Stores data fetched from the API or filtered data
  loading: false, // Tracks loading state for fetch and update
  error: null, // Tracks any errors
};

// Fetch Increment Data
export const fetchIncrementData = createAsyncThunk(
  'increment/fetchIncrementData',
  async (_, {rejectWithValue}) => {
    try {
      const token = localStorage.getItem('googleIdToken');
      const response = await axios.get(`${config.apiURL}/increment-data`, {
        headers: {Authorization: `Bearer ${token}`},
      });
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error('Error fetching increment data:', error);
      return rejectWithValue(
        error.response?.data || 'Failed to fetch increment data'
      );
    }
  }
);

// Fetch Filtered Increment Data Based on Review VP
export const fetchFilteredIncrementData = createAsyncThunk(
  'increment/fetchFilteredIncrementData',
  async (namesArray, {getState, rejectWithValue}) => {
    try {
      const token = localStorage.getItem('googleIdToken');
      const response = await axios.get(`${config.apiURL}/increment-data`, {
        headers: {Authorization: `Bearer ${token}`},
      });
      // Filter the data based on Review VP being in the namesArray
      const filteredData = response.data.filter((item) =>
        namesArray.includes(item['Review VP'])
      );
      return Array.isArray(filteredData) ? filteredData : [];
    } catch (error) {
      console.error('Error fetching filtered increment data:', error);
      return rejectWithValue(
        error.response?.data || 'Failed to fetch filtered increment data'
      );
    }
  }
);

// Fetch Filtered Increment Data Based on Tagging
export const fetchTagFilteredIncrementData = createAsyncThunk(
  'increment/fetchTagFilteredIncrementData',
  async (_, {rejectWithValue}) => {
    try {
      const token = localStorage.getItem('googleIdToken');
      const response = await axios.get(`${config.apiURL}/increment-data`, {
        headers: {Authorization: `Bearer ${token}`},
      });
      // Filter the data where Tagging column has the exact value "DE/MLE/Tech/BI"
      const filteredData = response.data.filter(
        (item) => item['Tagging'] === 'DE/MLE/Tech/BI'
      );
      return Array.isArray(filteredData) ? filteredData : [];
    } catch (error) {
      console.error(
        'Error fetching filtered increment data by Tagging:',
        error
      );
      return rejectWithValue(
        error.response?.data || 'Failed to fetch filtered increment data'
      );
    }
  }
);

// Update Increment Data
export const updateIncrementData = createAsyncThunk(
  'increment/updateIncrementData',
  async (updates, {rejectWithValue}) => {
    try {
      const token = localStorage.getItem('googleIdToken');
      const response = await axios.post(
        `${config.apiURL}/increment-data/update`,
        updates,
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating increment data:', error);
      return rejectWithValue(
        error.response?.data || 'Failed to update increment data'
      );
    }
  }
);

// Increment Slice
const incrementSlice = createSlice({
  name: 'increment',
  initialState,
  reducers: {
    resetIncrementState: (state) => {
      state.incrementData = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Increment Data
      .addCase(fetchIncrementData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIncrementData.fulfilled, (state, action) => {
        state.loading = false;
        state.incrementData = action.payload; // Store the full data
      })
      .addCase(fetchIncrementData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Filtered Increment Data Based on Review VP
      .addCase(fetchFilteredIncrementData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFilteredIncrementData.fulfilled, (state, action) => {
        state.loading = false;
        state.incrementData = action.payload; // Overwrite incrementData with filtered data
      })
      .addCase(fetchFilteredIncrementData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Filtered Increment Data Based on Tagging
      .addCase(fetchTagFilteredIncrementData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTagFilteredIncrementData.fulfilled, (state, action) => {
        state.loading = false;
        state.incrementData = action.payload; // Overwrite incrementData with filtered data
      })
      .addCase(fetchTagFilteredIncrementData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Increment Data
      .addCase(updateIncrementData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateIncrementData.fulfilled, (state) => {
        state.loading = false;
        state.error = null; // Clear errors on success
      })
      .addCase(updateIncrementData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export Actions
export const {resetIncrementState} = incrementSlice.actions;

// Export Reducer
export default incrementSlice.reducer;
