import React from 'react';
import {Autocomplete, TextField, Typography} from '@mui/material';

const index = ({handleUserChange, rowdata, selectedUser}) => {
	return (
		<Autocomplete
			sx={{
				'& .MuiAutocomplete-listbox': {
					maxHeight: 200,
					overflowY: 'auto',
					// Custom scrollbar styling
					'&::-webkit-scrollbar': {
						width: '1px',
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#888',
						borderRadius: '4px',
					},
					'&::-webkit-scrollbar-thumb:hover': {
						backgroundColor: '#555',
					},
					'&::-webkit-scrollbar-track': {
						backgroundColor: '#f1f1f1',
					},
				},
			}}
			fullWidth
			options={rowdata ?? []}
			getOptionLabel={(option) => option?.EMAILID ?? ''}
			renderOption={(props, option) => (
				<Typography
					{...props}
					style={{fontSize: '12px'}}
				>
					{option?.EMAILID ?? ''}
				</Typography>
			)}
			value={selectedUser ?? ''}
			onChange={(e, option) => handleUserChange(e, option)}
			renderInput={(params) => (
				<TextField
					{...params}
					label='Search Users'
					variant='outlined'
					size='small'
					placeholder='Select An EmailId'
				/>
			)}
			autoHighlight
			isOptionEqualToValue={(option, value) =>
				option?.ID === value?.ID ?? value === ''
			}
		/>
	);
};

export default index;
