import axios from 'axios';
import config from '../../../config';
const fetchCommentsApi = async (googleIdToken) => {
	try {
		const result = await axios.get(`${config.apiURL}/comments`, {
			headers: {
				Authorization: `Bearer ${googleIdToken}`,
			},
		});
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (err) {
		return [];
	}
};

export {fetchCommentsApi};
