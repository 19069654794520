import { Container, CssBaseline, Grid } from '@mui/material';
import React from 'react';
import ManagerFeedback from '../../components/Userprofile/ManagerFeedback';
import NavBar from '../../components/Userprofile/NavBar';
import UserProfileSidebar from '../../components/Userprofile/UserProfileSidebar';
import VPWiseSelectionCard from '../../components/Userprofile/VPWiseSelectionCard';
import './UserProfile.css';

const UserProfile = () => {
  return (
    <>
      <CssBaseline />
      <NavBar />
      <Container
        maxWidth='xl' // Keep the maxWidth to 'lg' for large screens
        className='scrollable-container'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 64px)', // Adjust height if necessary
          overflowY: 'auto',
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12} // Full width on small screens
            sm={4} // One-third width on medium screens
            md={3} // One-fourth width on larger screens
          >
            <div className='fixed-sidebar'>
              <VPWiseSelectionCard />
              <UserProfileSidebar />
            </div>
          </Grid>
          <Grid
            item
            xs={12} // Full width on small screens
            sm={8} // Two-thirds width on medium screens
            md={9} // Three-fourths width on larger screens
            sx={{
              mt: 0, // Remove unnecessary margins on small screens
            }}
          >
            <ManagerFeedback sx={{ mt: 0, mb: 0 }} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default UserProfile;
