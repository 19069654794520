import React, {useEffect, useState} from 'react';
import {Grid, Typography, Button} from '@mui/material';
import {deleteUserRoleByIdApi} from '../Utilities/v2Apis/deleteUserRoleById';
import {getUserRolesByEmailApi} from '../Utilities/v2Apis/getUserRolesByEmail';
import {useDispatch, useSelector} from 'react-redux';
import {addRolesAssignedToUser} from '../../redux/adminReducer';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CustomAlert from '../Alerts/CustomAlert';

const UserRoles = ({email, handleClose}) => {
	const data = useSelector((state) => state.AdminPageData.rolesAssignedToUser);
	const [alert, setAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState(null);
	const [success, setSuccess] = useState(false);
	const dispatch = useDispatch();

	// Fetch roles on component mount
	useEffect(() => {
		getUserRolesByEmailApi(email).then((result) => {
			dispatch(addRolesAssignedToUser(result));
		});
	}, [dispatch, email]);

	// Handle delete role
	const handleDelete = async (row) => {
		try {
			// Call the API to delete the role
			await deleteUserRoleByIdApi(row).then((res) => {
				if (res.status === 200) {
					setAlertMessage(res.data.message);
					setAlert(true);
					setSuccess(true);
					getUserRolesByEmailApi(email).then((result) => {
						dispatch(addRolesAssignedToUser(result));
					});
				} else {
					setAlertMessage(res.response.data.message);
					setAlert(true);
					setSuccess(false);
				}
			});
		} catch (error) {
			setAlertMessage(error?.message);
			setAlert(true);
			setSuccess(false);
		}
		setTimeout(() => {
			handleAlertClose();
		}, 2000);
	};
	const handleAlertClose = () => {
		setAlert(false);
	};
	return (
		<Grid
			container
			style={{
				backgroundColor: 'white',
				height: 'fit-content',
				width: '90vw',
				padding: '1rem',
				borderRadius: '1rem',
				border: '1px solid #ccc', // Border for the main grid
				boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional box-shadow for depth
			}}
		>
			{/* Close Button */}
			<Grid
				item
				xs={12}
				style={{display: 'flex', justifyContent: 'flex-end'}}
			>
				<CloseIcon
					style={{
						cursor: 'pointer',
						color: 'red',
						border: '1px solid red',
						borderRadius: '50%',
						padding: '0.25rem',
					}}
					onClick={handleClose}
				/>
			</Grid>
			<CustomAlert
				alert={alert}
				message={alertMessage}
				handleClose={handleAlertClose}
				success={success}
			/>
			{/* User Roles Data */}
			{data.map((item, index) => (
				<Grid
					container
					spacing={2}
					key={index}
					style={{
						padding: '1rem',
						borderBottom: '1px solid #ddd',
						alignItems: 'center',
					}}
				>
					<Grid
						item
						xs={2}
					>
						<Typography
							variant='body1'
							fontWeight='bold'
						>
							Name:
						</Typography>
						<Typography variant='body2'>{item.NAME}</Typography>
					</Grid>
					<Grid
						item
						xs={2}
					>
						<Typography
							variant='body1'
							fontWeight='bold'
						>
							ROLE_NAME:
						</Typography>
						<Typography variant='body2'>{item.ROLE_NAME}</Typography>
					</Grid>
					<Grid
						item
						xs={2}
					>
						<Typography
							variant='body1'
							fontWeight='bold'
						>
							ROLE_GROUP:
						</Typography>
						<Typography variant='body2'>{item.ROLE_GROUP}</Typography>
					</Grid>
					<Grid
						item
						xs={3}
					>
						<Typography
							variant='body1'
							fontWeight='bold'
						>
							EMAIL:
						</Typography>
						<Typography variant='body2'>{item.EMAIL}</Typography>
					</Grid>
					<Grid
						item
						xs={3}
						style={{display: 'flex', justifyContent: 'flex-end'}}
					>
						<Button
							variant='outlined'
							color='error'
							onClick={() => handleDelete(item)}
							startIcon={<DeleteForeverIcon />}
						>
							Delete
						</Button>
					</Grid>
				</Grid>
			))}
		</Grid>
	);
};

export default UserRoles;
