import React from 'react';
import {Button} from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import classes from './floatDivStyles.module.css';

const FloatDiv = ({
	handleDossierCorrection,
	handleEditButtons,
	openEditIcons,
}) => {
	return (
		<div className={classes.floatDiv}>
			{openEditIcons && (
				<div style={{float: 'left'}}>
					<Button
						variant='contained'
						aria-label='edit'
						endIcon={<EditIcon />}
						onClick={handleDossierCorrection}
						sx={{
							backgroundColor: '#f7901d',
							color: '#ffffff',
							border: '2px solid #ffffff',
							borderRadius: '30px',
							'&:hover': {
								backgroundColor: '#f7901d',
								borderColor: '#ffffff',
							},
							textAlign: 'center',
						}}
					>
						Dossier Correction
					</Button>
					<a
						href='https://www.appsheet.com/start/1525b785-2afc-4404-9f2a-c673c245eec7'
						target='_blank'
						rel='noopener noreferrer'
					>
						<Button
							variant='contained'
							aria-label='edit'
							endIcon={<EditIcon />}
							sx={{
								backgroundColor: '#f7901d',
								color: '#ffffff',
								border: '2px solid #ffffff',
								borderRadius: '30px',
								'&:hover': {
									backgroundColor: '#f7901d',
									borderColor: '#ffffff',
								},
								textAlign: 'center',
							}}
						>
							Projections Correction
						</Button>
					</a>
				</div>
			)}
			<Button
				variant='contained'
				aria-label='edit'
				onClick={handleEditButtons}
				endIcon={<KeyboardDoubleArrowRightIcon />}
				sx={{
					backgroundColor: '#f7901d',
					color: '#ffffff',
					border: '2px solid #ffffff',
					borderRadius: '30px',
					'&:hover': {
						backgroundColor: '#f7901d',
						borderColor: '#ffffff',
					},
				}}
			>
				Corrections
			</Button>
		</div>
	);
};

export default FloatDiv;
