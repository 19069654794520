import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {format} from 'date-fns'; // Ensure you're importing the format function from date-fns or a similar library
import React, {useState} from 'react';
import {useSelector} from 'react-redux';

const CalibrationFeedback = () => {
  const selectedPerson = useSelector((state) => state.user.selectedPerson);
  const [openSalaryRanges, setOpenSalaryRanges] = useState(false);
  const [openCompensationDetails, setOpenCompensationDetails] = useState(false);
  const user = useSelector((state) => state.PowerBi.user);
  const PMS_ROLE = user?.PMS_ACCESS;

  // Common styles for truncating text with ellipsis
  const ellipsisStyles = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 150, // Limit the width to show ellipsis for longer text
  };
  const currentYear = new Date().getFullYear();
  const currentYearLabel = currentYear - 1; // e.g., 2024
  const previousYearLabel = currentYear - 2; // e.g., 2023
  const yearMinusTwoLabel = currentYear - 3; // e.g., 2022
  const yearMinusThreeLabel = currentYear - 4;
  // Truncate text and add ellipsis based on length
  const truncateText = (text, maxLength) => {
    if (!text) return ' ';
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength - 3)}...`;
    }
    return text.length > 20 ? `${text.slice(0, 20)}...` : text;
  };

  const formatDate = (dateString) => {
    // Handle the special case for '01-01-1900'
    if (dateString === '01-01-1900') {
      return '';
    }

    try {
      // Parse the date
      const parsedDate = new Date(dateString);

      // Check if the date is invalid
      if (!dateString || isNaN(parsedDate)) {
        return ''; // Save "N/A" for invalid dates
      }

      // Return formatted date
      return format(parsedDate, 'MMMM d, yyyy');
    } catch {
      // Fallback to "N/A" in case of any errors
      return '';
    }
  };

  const formattedJoiningDate = formatDate(
    selectedPerson?.['Current_yr_Salary_Change_Date']
  );
  const formattedJoiningDate1 = formatDate(
    selectedPerson?.['Current-1_yr_Salary_Change_Date']
  );
  const formattedJoiningDate2 = formatDate(
    selectedPerson?.['Current-2_yr_Salary_Change_Date']
  );
  const formattedJoiningDate3 = formatDate(
    selectedPerson?.['Current-3_yr_Salary_Change_Date']
  );

  // Render header with tooltip if the text is too long
  const renderHeaderWithTooltip = (label) => (
    <TableCell
      sx={{
        padding: 1,
        border: '1px solid #ddd',
        fontSize: '0.875rem',
        ...ellipsisStyles,
        textAlign: 'center',
      }}
    >
      <Tooltip title={label} placement='top'>
        <Typography
          variant='body2'
          sx={{fontWeight: 'bold', textAlign: 'center'}}
        >
          {truncateText(label, 20)} {/* Adjust maxLength as needed */}
        </Typography>
      </Tooltip>
    </TableCell>
  );

  // Render cell with tooltip if the text is too
  const renderCellWithTooltip = (label, value = '') => (
    <TableCell
      sx={{
        padding: 1,
        border: '1px solid #ddd',
        fontSize: '0.875rem',
        ...ellipsisStyles,
        textAlign: 'center',
      }}
    >
      <Tooltip title={value || ' '} placement='top'>
        <Typography variant='body2' sx={{textAlign: 'center'}}>
          {truncateText(value, 20)} {/* Adjust maxLength as needed */}
        </Typography>
      </Tooltip>
    </TableCell>
  );

  return (
    <Grid container spacing={2}>
      {/* Compensation Details Box */}
      <Grid item xs={12}>
        <Paper elevation={2} sx={{padding: 2, borderRadius: 2}}>
          <Grid
            container
            spacing={2}
            sx={{overflowX: 'auto', alignItems: 'center'}}
          >
            {/* Header with the title and arrow button */}
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant='h6'
                sx={{
                  color: '#0047AB',
                  fontSize: {xs: '1.1rem', sm: '1.4rem'},
                  color: '#F7901D',
                  fontWeight: 500,
                  fontFamily: '"Nanum Myeongjo", serif',
                }}
              >
                Compensation Details
              </Typography>
              <IconButton
                onClick={() =>
                  setOpenCompensationDetails(!openCompensationDetails)
                }
              >
                {openCompensationDetails ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
              </IconButton>
            </Grid>

            {/* Collapsible content */}
            {openCompensationDetails && (
              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  sx={{borderRadius: 2, overflow: 'auto'}}
                >
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        {renderHeaderWithTooltip('Year')}
                        {renderHeaderWithTooltip('Effective Date')}
                        {renderHeaderWithTooltip(
                          'Increase type (Promotion/Merit/Mid-Term)'
                        )}
                        {renderHeaderWithTooltip('Total Compensation')}
                        {renderHeaderWithTooltip('Base Cash')}
                        {renderHeaderWithTooltip('Base COLA')}
                        {renderHeaderWithTooltip('Base ESOPs')}
                        {renderHeaderWithTooltip('Bonus')}
                        {renderHeaderWithTooltip('Bonus Type Salary')}
                        {renderHeaderWithTooltip('Cash Bonus Target')}
                        {renderHeaderWithTooltip('ESOP Bonus Target')}
                        {renderHeaderWithTooltip('Current Cycle Comp')}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {renderCellWithTooltip('Year', currentYearLabel)}
                        {renderCellWithTooltip(
                          'Current_yr_Salary_Change_Date',
                          formattedJoiningDate
                        )}
                        {renderCellWithTooltip(
                          'Current_yr_Increase type (Promotion/Merit/Mid-Term)',
                          selectedPerson?.[
                            'Current_yr_Increase type (Promotion/Merit/Mid-Term)'
                          ]
                        )}
                        {renderCellWithTooltip(
                          'Total Compensation',
                          selectedPerson?.['Current_yr_Total_Comp_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Base Cash',
                          selectedPerson?.['Current_yr_Base_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Base COLA',
                          selectedPerson?.['Current_yr_COLA_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Base ESOPs',
                          selectedPerson?.['Current_yr_ESOPS_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Bonus',
                          selectedPerson?.['Current_yr_Bonus_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Bonus Type Salary',
                          selectedPerson?.['Current_yr_Bonus_type_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Cash Bonus Target',
                          selectedPerson?.['Current_yr_Bonus_Cash_Salary']
                        )}
                        {renderCellWithTooltip(
                          'ESOP Bonus Target',
                          selectedPerson?.['Current_yr_Bonus_ESOPs_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Current Cycle Comp',
                          selectedPerson?.['Current_yr_Bonus_Blend_Salary']
                        )}
                      </TableRow>
                      <TableRow>
                        {renderCellWithTooltip('Year', previousYearLabel)}
                        {renderCellWithTooltip(
                          'Effective Date',
                          formattedJoiningDate1
                        )}
                        {renderCellWithTooltip(
                          'Current-1_yr_Increase type (Promotion/Merit/Mid-Term)',
                          selectedPerson?.[
                            'Current-1_yr_Increase type (Promotion/Merit/Mid-Term)'
                          ]
                        )}
                        {renderCellWithTooltip(
                          'Total Compensation',
                          selectedPerson?.['Current-1_yr_Total_Comp_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Base Cash',
                          selectedPerson?.['Current-1_yr_Base_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Base COLA',
                          selectedPerson?.['Current-1_yr_COLA_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Base ESOPs',
                          selectedPerson?.['Current-1_yr_ESOPS_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Bonus',
                          selectedPerson?.['Current-1_yr_Bonus_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Bonus Type Salary',
                          selectedPerson?.['Current-1_yr_Bonus_type_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Cash Bonus Target',
                          selectedPerson?.['Current-1_yr_Bonus_Cash_Salary']
                        )}
                        {renderCellWithTooltip(
                          'ESOP Bonus Target',
                          selectedPerson?.['Current-1_yr_Bonus_ESOPs_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Current Cycle Comp',
                          selectedPerson?.['Current-1_yr_Bonus_Blend_Salary']
                        )}
                      </TableRow>
                      <TableRow>
                        {renderCellWithTooltip(
                          'Effective Date',
                          yearMinusTwoLabel
                        )}
                        {renderCellWithTooltip(
                          'Effective Date',
                          formattedJoiningDate2
                        )}
                        {renderCellWithTooltip(
                          'Current-2_yr_Increase type (Promotion/Merit/Mid-Term)',
                          selectedPerson?.[
                            'Current-2_yr_Increase type (Promotion/Merit/Mid-Term)'
                          ]
                        )}
                        {renderCellWithTooltip(
                          'Total Compensation',
                          selectedPerson?.['Current-2_yr_Total_Comp_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Base Cash',
                          selectedPerson?.['Current-2_yr_Base_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Base COLA',
                          selectedPerson?.['Current-2_yr_COLA_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Base ESOPs',
                          selectedPerson?.['Current-2_yr_ESOPS_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Bonus',
                          selectedPerson?.['Current-2_yr_Bonus_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Bonus Type Salary',
                          selectedPerson?.['Current-2_yr_Bonus_type_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Cash Bonus Target',
                          selectedPerson?.['Current-2_yr_Bonus_Cash_Salary']
                        )}
                        {renderCellWithTooltip(
                          'ESOP Bonus Target',
                          selectedPerson?.['Current-2_yr_Bonus_ESOPs_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Current-2_yr_Bonus_Blend_Salary',
                          selectedPerson?.['Current-2_yr_Bonus_Blend_Salary']
                        )}
                      </TableRow>
                      <TableRow>
                        {renderCellWithTooltip(
                          'Effective Date',
                          yearMinusThreeLabel
                        )}
                        {renderCellWithTooltip(
                          'Effective Date',
                          formattedJoiningDate3
                        )}
                        {renderCellWithTooltip(
                          'Current-3_yr_Increase type (Promotion/Merit/Mid-Term)',
                          selectedPerson?.[
                            'Current-3_yr_Increase type (Promotion/Merit/Mid-Term)'
                          ]
                        )}
                        {renderCellWithTooltip(
                          'Total Compensation',
                          selectedPerson?.['Current-3_yr_Total_Comp_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Base Cash',
                          selectedPerson?.['Current-3_yr_Base_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Base COLA',
                          selectedPerson?.['Current-3_yr_COLA_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Base ESOPs',
                          selectedPerson?.['Current-3_yr_ESOPS_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Bonus',
                          selectedPerson?.['Current-3_yr_Bonus_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Bonus Type Salary',
                          selectedPerson?.['Current-3_yr_Bonus_type_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Cash Bonus Target',
                          selectedPerson?.['Current-3_yr_Bonus_Cash_Salary']
                        )}
                        {renderCellWithTooltip(
                          'ESOP Bonus Target',
                          selectedPerson?.['Current-3_yr_Bonus_ESOPs_Salary']
                        )}
                        {renderCellWithTooltip(
                          'Current-3_yr_Bonus_Blend_Salary',
                          selectedPerson?.['Current-3_yr_Bonus_Blend_Salary']
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      {/* Salary Ranges Box */}
      {(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee') && (
        <Grid item xs={12}>
          <Paper elevation={2} sx={{padding: 2, borderRadius: 2}}>
            <Grid
              container
              spacing={2}
              sx={{overflowX: 'auto', alignItems: 'center'}}
            >
              {/* Header with the title and arrow button */}
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant='h6'
                  sx={{
                    color: '#0047AB',
                    fontSize: {xs: '1.1rem', sm: '1.4rem'},
                    color: '#F7901D',

                    fontWeight: 500,
                    fontFamily: '"Nanum Myeongjo", serif',
                  }}
                >
                  Salary Ranges
                </Typography>
                <IconButton
                  onClick={() => setOpenSalaryRanges(!openSalaryRanges)}
                >
                  {openSalaryRanges ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </IconButton>
              </Grid>

              {/* Collapsible content */}
              {openSalaryRanges && (
                <Grid item xs={12}>
                  <TableContainer
                    component={Paper}
                    sx={{
                      borderRadius: 2,
                      overflow: 'auto',
                      textAlign: 'center',
                    }}
                  >
                    <Table size='small' sx={{textAlign: 'center'}}>
                      <TableHead sx={{textAlign: 'center'}}>
                        <TableRow sx={{textAlign: 'center'}}>
                          {renderHeaderWithTooltip('Min Salary')}
                          {renderHeaderWithTooltip('Max Salary')}
                          {renderHeaderWithTooltip('Median Salary')}
                          {renderHeaderWithTooltip('10th Percentile')}
                          {renderHeaderWithTooltip('50th Percentile')}
                          {renderHeaderWithTooltip('90th Percentile')}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{textAlign: 'center'}}>
                          {renderCellWithTooltip(
                            'Min Salary',
                            selectedPerson?.Min_Sal
                          )}
                          {renderCellWithTooltip(
                            'Max Salary',
                            selectedPerson?.Max_Sal
                          )}
                          {renderCellWithTooltip(
                            'Median Salary',
                            selectedPerson?.Median_Sal
                          )}
                          {renderCellWithTooltip(
                            '10th Percentile',
                            selectedPerson?.['10th %tile_Sal']
                          )}
                          {renderCellWithTooltip(
                            '50th Percentile',
                            selectedPerson?.['50th %tile_Sal']
                          )}
                          {renderCellWithTooltip(
                            '90th Percentile',
                            selectedPerson?.['90th %tile_Sal']
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default CalibrationFeedback;
