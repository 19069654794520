import React, {useState, useEffect} from 'react';
import {Grid, CircularProgress} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import classes from './UserDetailsReports.module.css';
import {
	getUsers,
	getReportsByUser,
	addLoading,
	addReportsLoading,
} from '../../redux/adminReducer';
import {getAllUsersRefactoredApi} from '../../components/Utilities/apis/getAllUsers';
import getUserReports from '../../components/Utilities/v2Apis/getUserReports';
import CustomAlert from '../../components/Alerts/CustomAlert';
import Error from '../../components/ErrorPage/Error';
import UserReportForm from './UserReportForm/UserReportForm';
import AddReportToUser from './AddReportsPopUp';
const UserDetailsReports = () => {
	const dispatch = useDispatch();
	const [alert, setAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState(null);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [text, setText] = useState('');
	const [selectedUser, setSelectedUser] = useState({});
	const rowdata = useSelector((state) => state.AdminPageData.usersDetails);
	const isLoading = useSelector((state) => state.AdminPageData.isLoading);

	const getData = () => {
		dispatch(addLoading(true));
		getAllUsersRefactoredApi().then((data) => {
			if (data.length === 0) {
				setError(true);
				setText('something went wrong');
			} else {
				setError(false);
				dispatch(getUsers(data));
			}
			dispatch(addLoading(false));
		});
	};

	const handleAlert = (res) => {
		if (res.status === 200) {
			setAlertMessage(res.data.message);
			setAlert(true);
			setSuccess(true);
		} else {
			setAlertMessage(res.response.data.message);
			setAlert(true);
			setSuccess(false);
		}
	};

	const handleAlertClose = () => {
		setAlert(false);
	};

	const handleSelectedUser = (user) => {
		setSelectedUser(user);
	};

	const handleUserChange = (e, user) => {
		e.preventDefault();
		setSelectedUser(user ?? {});
		if (user?.userId) {
			dispatch(addReportsLoading(true));
			getUserReports(user?.userId).then((data) => {
				dispatch(getReportsByUser(data));
				dispatch(addReportsLoading(false));
			});
		} else {
			dispatch(getReportsByUser({}));
			dispatch(addReportsLoading(false));
		}
	};

	//  Execute When Page First Loads to get users data
	useEffect(() => {
		dispatch(addLoading(true));
		getAllUsersRefactoredApi().then((data) => {
			if (data.length === 0) {
				setError(true);
				setText('something went wrong');
			} else {
				setError(false);
				dispatch(getUsers(data));
			}
			dispatch(addLoading(false));
			dispatch(getReportsByUser({}));
		});
	}, [dispatch]);

	const renderContent = () => {
		if (error)
			return (
				<Error
					text={text}
					handleError={getData}
				/>
			);
		if (isLoading)
			return (
				<CircularProgress
					style={{position: 'fixed', color: 'orange', top: '50%', right: '50%'}}
				/>
			);
		return (
			<Grid
				container
				className={classes.container}
				sx={{
					'&::-webkit-scrollbar': {
						width: 6,
					},
					'&::-webkit-scrollbar-track': {
						backgroundColor: '#F4F4F4',
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#FD9327',
						borderRadius: 1,
					},
				}}
			>
				<CustomAlert
					alert={alert}
					message={alertMessage}
					handleClose={handleAlertClose}
					success={success}
				/>
				<UserReportForm
					formdata={selectedUser}
					userId={selectedUser?.userId}
					handleAlert={handleAlert}
					handleSelectedUser={handleSelectedUser}
					handleUserChange={handleUserChange}
					rowdata={rowdata}
				/>
				<AddReportToUser userId={selectedUser?.userId} />
			</Grid>
		);
	};

	return renderContent();
};

export default UserDetailsReports;
