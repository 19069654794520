import axios from 'axios';
import config from './../../../config';

const userToReportsMapping = async (props) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const res = await axios.post(`${config.apiURL}/userToReportsMapping`, {
			Authorization: `Bearer ${token}`,
			props,
		});
		return res;
	} catch (err) {
		return err;
	}
};
export default userToReportsMapping;
