// Function to Get All Users Data to Admin Screen table
import axios from 'axios';
import config from './../../../config';
const getUserReports = async (userId) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const result = await axios.get(
			`${config.apiURL}/getUserReports/v2/${userId}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return Object.keys(result?.data).length !== 0 ? result?.data : {};
	} catch (err) {
		return {};
	}
};

const getUserReportsGroupByOrgName = async (userId) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const result = await axios.get(
			`${config.apiURL}/getUserReportsById/v2/${userId}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		console.log(result, 'result');
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (err) {
		return [];
	}
};

const getAllPBIRoles = async () => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const result = await axios.get(
			`${config.apiURL}/v2/getAllUserRolesMappingPBI`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (err) {
		return [];
	}
};

const getAllPBINamesApi = async () => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const result = await axios.get(`${config.apiURL}/v2/getAllUserNamesPBI`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (err) {
		return [];
	}
};

export default getUserReports;
export {getUserReportsGroupByOrgName, getAllPBIRoles, getAllPBINamesApi};
