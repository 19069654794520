import {createSlice} from '@reduxjs/toolkit';

const formReducer = createSlice({
	name: 'FormDetails',
	initialState: {
		AudienceValues: [],
		TwoWayFormData: [],
		RevenueFormData: [],
	},
	reducers: {
		getAudiences(state, action) {
			state.AudienceValues = action.payload;
		},
		setTwoWayFormData(state, action) {
			state.TwoWayFormData = action.payload;
		},
		addRevenueFormData(state, action) {
			console.log(action.payload, 'coming to formreducer');
			state.RevenueFormData = action.payload;
		},
	},
});

export const {getAudiences, setTwoWayFormData, addRevenueFormData} =
	formReducer.actions;
export default formReducer.reducer;
