/**
 * Redux Store Configuration
 * This module configures the Redux store for the application,
 * including middleware and state persistence.
 */

import {configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import adminReducer from './adminReducer';
import commentsReducer from './commentSlice';
import formReducer from './formReducer';
import glossaryReducer, {getGlossary} from './glossaryReducer';
import incrementReducer from './IncrementSlice'; // Import the increment slice
import UserReducer from './userReducer';
import userReducer from './userSlice'; // Ensure this is the correct slice you want to persist

// Configuration for Redux state persistence.
const persistConfig = {
  key: 'user', // Use 'user' to only persist the user slice
  storage,
};

const persistConfigs = {
  key: 'pms',
  storage,
};

// Create a persisted reducer with the user reducer
const persistedReducer = persistReducer(persistConfig, UserReducer);

const persistedUserReducer = persistReducer(persistConfigs, userReducer);
const persistedIncrementReducer = persistReducer(
  persistConfigs,
  incrementReducer
);

// Configure the Redux store with the persisted reducer and other reducers
export const store = configureStore({
  reducer: {
    PowerBi: persistedReducer, // Changed from persistedReducer to adminReducer
    AdminPageData: adminReducer,
    glossary: glossaryReducer,
    Form: formReducer,
    comments: commentsReducer,
    user: persistedUserReducer, // Use the persisted reducer for user
    increment: persistedIncrementReducer,
  },
  // Middleware configuration
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Create a Redux persistor for the store
export const persistor = persistStore(store);
export {getGlossary};
