import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config';

// Load initial state without using localStorage
const initialState = {
  vpNames: [],
  taggingOptions: [],
  cpNames: [],
  peopleUnderVP: [],
  calibrationStatuses: [], // Added calibration statuses state
  selectedVP: '',
  selectedTagging: '',
  selectedCP: '',
  selectedPerson: '', // Default to an empty string or null
  selectedCalibrationStatus: '', // Add this line for the selected calibration status
  loading: false, // Track loading state for updates
  error: null, // Track error state
  currentIndex: 0, // Initialize currentIndex
  editableValues: {}, // Add this line for the editable values state
};

// Fetch Tagging Options
export const fetchTaggingOptions = createAsyncThunk(
  'user/fetchTaggingOptions',
  async (vpName) => {
    try {
      const token = localStorage.getItem('googleIdToken'); // token stays in localStorage for auth purposes
      const response = await axios.get(`${config.apiURL}/tagging/${vpName}`, {
        headers: {Authorization: `Bearer ${token}`},
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching tagging options:', error);
      return [];
    }
  }
);

// Fetch Calibration Status from API
export const fetchCalibrationStatus = createAsyncThunk(
  'user/fetchCalibrationStatus',
  async () => {
    try {
      const token = localStorage.getItem('googleIdToken');
      const response = await axios.get(`${config.apiURL}/calibration-status`, {
        headers: {Authorization: `Bearer ${token}`},
      });
      return Array.isArray(response?.data) ? response.data : [];
    } catch (error) {
      console.error('Error fetching calibration status:', error);
      return [];
    }
  }
);

// Fetch CP Names from API
export const fetchCpNames = createAsyncThunk(
  'user/fetchCpNames',
  async ({vpName, tagging}) => {
    try {
      const token = localStorage.getItem('googleIdToken');
      const response = await axios.get(`${config.apiURL}/cpnames`, {
        headers: {Authorization: `Bearer ${token}`},
        params: {vpName, tagging},
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching CP names:', error);
      return [];
    }
  }
);

// Fetch VP Names from API
export const fetchVpNames = createAsyncThunk('user/fetchVpNames', async () => {
  try {
    const token = localStorage.getItem('googleIdToken');
    const response = await axios.get(`${config.apiURL}/CurrentVp`, {
      headers: {Authorization: `Bearer ${token}`},
    });
    return Array.isArray(response?.data) ? response?.data : [];
  } catch (error) {
    console.error('Error fetching VP names:', error);
    return [];
  }
});

// Fetch People Under VP
export const fetchPeopleUnderVP = createAsyncThunk(
  'user/fetchPeopleUnderVP',
  async ({vpName, tagging, cpName, calibrationStatus}) => {
    try {
      const token = localStorage.getItem('googleIdToken');

      // Format calibrationStatus into a comma-separated string if it's an array
      const formattedCalibrationStatus = Array.isArray(calibrationStatus)
        ? calibrationStatus.join(',')
        : calibrationStatus;

      const response = await axios.get(`${config.apiURL}/vpdetails/${vpName}`, {
        headers: {Authorization: `Bearer ${token}`},
        params: {
          tagging,
          cpName,
          calibrationStatus: formattedCalibrationStatus, // Pass the formatted string
        },
      });

      return Array.isArray(response?.data) ? response.data : [];
    } catch (error) {
      console.error('Error fetching people under VP:', error);
      return [];
    }
  }
);

// Update Calibration Data
export const updateCalibrationData = createAsyncThunk(
  'user/updateCalibrationData',
  async ({employeeId, data}, {rejectWithValue}) => {
    try {
      const token = localStorage.getItem('googleIdToken');
      const response = await axios.put(
        `${config.apiURL}/calibration-data/${employeeId}`,
        data,
        {
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      return response.data; // Return the response data for further use
    } catch (error) {
      console.error('Error updating calibration data:', error);
      return rejectWithValue(error.response.data); // Return error data for handling
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSelectedVP: (state, action) => {
      state.selectedVP = action.payload;
    },
    setSelectedTagging: (state, action) => {
      state.selectedTagging = action.payload;
    },
    setSelectedCP: (state, action) => {
      state.selectedCP = action.payload;
    },
    setSelectedPerson: (state, action) => {
      state.selectedPerson = action.payload;
      // console.log(state.selectedPerson);
    },
    setSelectedCalibrationStatus: (state, action) => {
      state.selectedCalibrationStatus = action.payload;
    },
    setPeopleUnderVP: (state, action) => {
      // Add this new reducer
      state.peopleUnderVP = action.payload; // Set peopleUnderVP
    },
    resetState: (state) => {
      state.selectedVP = '';
      state.selectedTagging = '';
      state.peopleUnderVP = []; // Reset peopleUnderVP
      state.selectedCP = '';
      state.selectedPerson = null;
      state.selectedCalibrationStatus = ''; // Reset selected calibration status
      state.currentIndex = 0; // Reset currentIndex
    },
    setCurrentIndex: (state, action) => {
      state.currentIndex = action.payload; // Set the current index
    },
    setEditableValues: (state, action) => {
      state.editableValues = action.payload; // Set the editable values
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVpNames.fulfilled, (state, action) => {
        state.vpNames = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(fetchTaggingOptions.fulfilled, (state, action) => {
        state.taggingOptions = action.payload;
      })
      .addCase(fetchCpNames.fulfilled, (state, action) => {
        state.cpNames = action.payload;
      })
      .addCase(fetchPeopleUnderVP.fulfilled, (state, action) => {
        const people = Array.isArray(action.payload) ? action.payload : [];
        state.peopleUnderVP = people; // Update peopleUnderVP
        state.currentIndex = 0; // Reset index when new data is fetched
      })
      .addCase(fetchCalibrationStatus.fulfilled, (state, action) => {
        state.calibrationStatuses = Array.isArray(action.payload)
          ? action.payload
          : [];
      })
      .addCase(updateCalibrationData.pending, (state) => {
        state.loading = true; // Set loading state to true while updating
        state.error = null; // Clear previous errors
      })
      .addCase(updateCalibrationData.fulfilled, (state, action) => {
        state.loading = false; // Reset loading state on success
        console.log('Calibration data updated successfully:', action.payload);
      })
      .addCase(updateCalibrationData.rejected, (state, action) => {
        state.loading = false; // Reset loading state on error
        state.error = action.payload || 'Failed to update calibration data'; // Handle error
        console.error('Error updating calibration data:', action.error);
      });
  },
});

// Exporting actions
export const {
  setSelectedVP,
  setSelectedTagging,
  setSelectedCP,
  setSelectedPerson,
  setSelectedCalibrationStatus, // Export the new action
  setPeopleUnderVP, // Export the new action
  resetState,
  setCurrentIndex, // Export the new action
  setEditableValues, // Export the new action
} = userSlice.actions;

export default userSlice.reducer;
