import {Button} from '@mui/material';
import {models} from 'powerbi-client';
import {PowerBIEmbed} from 'powerbi-client-react';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useOutletContext} from 'react-router-dom';
import Comment from '../../components/comments/Comment';
import DrawerModal from '../../components/Drawer/Drawer';
import DraggableModal from '../../components/Modal/DraggableModal';
import CustomModal from '../../components/Modal/CustomModal';
import {fetchCommentsApi} from '../../components/Utilities/apis/commentsApis';
import FloatDiv from './FloatDiv';
import {
	addCommentsToReports,
	addCurrentPageName,
	addReportSlice,
	setisReportLoaded,
} from '../../redux/userReducer';
import classes from './floatDivStyles.module.css';
import './PowerBiReport.css';
import PrintReportBI from './PrintReport';
import RevenuDataComponent from '../RevenueDataModal/RevenuDataModal';

const PowerBIReport = (props) => {
	//code to Zoom the report , will set if needed
	// let currentZoom = await window.Report.getZoom();
	// await window.Report.setZoom(1);
	// zoomLevel: 2,
	const navigate = useNavigate(); // <-- useNavigate hook for navigation

	const googleIdToken = useOutletContext(); // <-- access context value
	const reportRef = useRef(null);
	const {selectedReport, embedUrl, embedToken, handleEmbedError} = props;
	const [openEditIcons, setOpenEditIcons] = useState(false);
	const [open, setOpen] = useState(false);
	const [openComments, setOpenComments] = useState(false);
	const dispatch = useDispatch();
	const allSliceData = useSelector((state) => state.PowerBi.reportSliceState);
	const isReportLoaded = useSelector((state) => state.PowerBi.isReportLoaded);
	const comments = useSelector((state) => state.PowerBi.commentsOfReports);
	const currentPage = useSelector((state) => state.PowerBi.currentPage);
	let user = useSelector((state) => state.PowerBi.user);
	let CurrentPageComments = comments?.[selectedReport?.PBIREPORTID]?.filter(
		(comment) =>
			comment?.REPORT?.trim().toLowerCase() ===
			currentPage?.trim().toLowerCase()
	);
	const handleEditButtons = () => {
		setOpenEditIcons(!openEditIcons);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleDossierCorrection = () => {
		setOpen(true);
	};
	useEffect(() => {
		if (
			selectedReport?.ReportName?.trim() === 'Financial Performance' &&
			isReportLoaded
		) {
			fetchCommentsApi({googleIdToken}).then((result) =>
				dispatch(addCommentsToReports([selectedReport?.PBIREPORTID, result]))
			);
		}
	}, [
		dispatch,
		googleIdToken,
		isReportLoaded,
		selectedReport?.PBIREPORTID,
		selectedReport?.ReportName,
	]);

	return (
		<div
			style={{
				display: 'flex',
			}}
		>
			<div style={{flex: 1}}>
				<PowerBIEmbed
					ref={reportRef}
					cssClassName={'PowerBiReport'}
					embedConfig={{
						type: 'report', // Supported types: report, dashboard, tile, visual and qna
						id: selectedReport?.PBIREPORTID,
						pageName: 'TA_OneData_PBI_Embed',
						embedUrl: embedUrl,
						accessToken: embedToken,
						tokenType: models.TokenType.Embed,
						slicers: allSliceData[selectedReport?.PBIREPORTID],
						settings: {
							panes: {
								filters: {
									visible: false,
								},
								pageNavigation: {
									visible: false,
								},
							},
							filterPaneEnabled: false,
							navContentPaneEnabled: true,
							background: 0,
						},
					}}
					eventHandlers={
						new Map([
							[
								'loaded',
								async function () {
									console.log('Report is Loading');
									dispatch(setisReportLoaded(false));
								},
							],
							[
								'rendered',
								async function () {
									const pages = await window.Report.getPages();
									// Retrieve the active page.
									let page = pages.filter(function (page) {
										return page.isActive;
									})[0];
									const visuals = await page.getVisuals();
									// Retrieve the target visual.
									let slicerS = visuals.filter(function (visual) {
										return visual.type === 'slicer';
									});
									const slices = slicerS.map(async (slicer) => {
										const s = await slicer.getSlicerState();
										return [s.filters, slicer.name];
									});
									const allSlicesReport = await Promise.all(slices);
									dispatch(
										addReportSlice([
											allSlicesReport.filter((item) => item[0].length > 0),
											window.Report.config.id,
											page.displayName,
										])
									);
									dispatch(addCurrentPageName(page?.displayName));
									dispatch(setisReportLoaded(true));
								},
							],
							[
								'error',
								function (event) {
									// handleEmbedError();
									console.log(event);
									if (
										event.detail.errorCode == 401 ||
										event.detail.errorCode == 403
									) {
										handleEmbedError();
									}
								},
							],
						])
					}
					getEmbeddedComponent={(embeddedReport) => {
						window.Report = embeddedReport;
					}}
				/>
			</div>
			<DrawerModal
				open={openComments}
				handleClose={() => {
					setOpenComments(!openComments);
				}}
			>
				<Comment commentData={CurrentPageComments?.[0]} />
			</DrawerModal>

			{user?.ISADMIN &&
				selectedReport?.ReportName?.trim() === 'Financial Performance' && (
					<Button
						className={classes.floatDiv}
						onClick={() => {
							setOpenComments(!openComments);
						}}
					>
						Add Comments
					</Button>
				)}

			<CustomModal
				open={open}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<>
					<RevenuDataComponent handleClose={handleClose} />
				</>
			</CustomModal>
			{selectedReport?.ReportName === 'Projections' && user?.ISADMIN && (
				<FloatDiv
					handleEditButtons={handleEditButtons}
					handleDossierCorrection={handleDossierCorrection}
					openEditIcons={openEditIcons}
				/>
			)}
			{/* ref is pointing to the report to print */}
			{selectedReport?.ReportName === 'PMS Completion Status' && (
				<PrintReportBI ref={reportRef} />
			)}
			{/* New Navigate to Profile Button */}
			{/* {selectedReport?.ReportName?.trim() === 'PMS Completion Status' && (
        <Button
          className={classes.floatDiv}
          onClick={() => {
            navigate('/userprofile'); // Navigate to the /userprofile page
          }}
          style={{
            backgroundColor: '#FF9800', // Orange color similar to the image
            color: '#FFFFFF', // White text
            borderRadius: '20px', // Rounded corners
            padding: '8px 20px',
            fontSize: '10px', // Padding to match the style
            marginTop: '115px', // Existing margin
            fontWeight: 'bold', // Make text bold like in the image
            border: '2px solid #FFFFFF', // White border
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow effect
          }}
        >
          Calibration Dashboard
        </Button>
      )} */}
		</div>
	);
};

export default PowerBIReport;
