import axios from 'axios';
import config from './../../../config';

const getUserRolesByEmailApi = async (email) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const result = await axios.get(`${config.apiURL}/v2/getUserRoleByEmail`, {
			headers: {
				Authorization: `Bearer ${token}`,
				email,
			},
		});
		return Array.isArray(result?.data) ? result?.data : [];
	} catch (err) {
		return [];
	}
};

export {getUserRolesByEmailApi};
