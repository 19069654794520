import React from 'react';
import logoImage from '../../components/Assets/correct_logo.png'; // Adjust the path as needed

const Logo = ({isMobile}) => {
  return (
    <img
      src={logoImage}
      alt='Tiger Analytics Logo'
      style={{
        height: isMobile ? '40px' : '60px',
        position: 'absolute', // This ensures the logo is placed at a fixed position
        top: '5px', // Distance from the top of the page
        left: '50px', // Distance from the left of the page
      }}
    />
  );
};

export default Logo;
