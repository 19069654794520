import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config';

const token = localStorage.getItem('googleIdToken');
// Async thunk for fetching comments
export const fetchComments = createAsyncThunk(
	'comments/fetchComments',
	async () => {
		const response = await axios.get(`${config.apiURL}/comments`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return Array.isArray(response?.data) ? response?.data : [];
	}
);

// Async thunk for adding a comment
export const addComment = createAsyncThunk(
	'comments/addComment',
	async (newComment) => {
		const response = await axios.post(`${config.apiURL}/comments`, newComment, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
		});
		return response.data;
	}
);

// Async thunk for updating a comment
export const updateComment = createAsyncThunk(
	'comments/updateComment',
	async (updatedComment) => {
		const token = localStorage.getItem('googleIdToken');
		const response = await axios.put(
			`${config.apiURL}/comments`,
			updatedComment,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			}
		);
		return response.data;
	}
);

const commentsSlice = createSlice({
	name: 'comments',
	initialState: {
		comments: [],
		status: 'idle',
		error: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchComments.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchComments.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.comments = action.payload;
			})
			.addCase(fetchComments.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(addComment.fulfilled, (state, action) => {
				state.comments.push(action.payload);
			})
			.addCase(updateComment.fulfilled, (state, action) => {
				const index = state.comments.findIndex(
					(comment) => comment.id === action.payload.id
				);
				if (index !== -1) {
					state.comments[index] = action.payload;
				}
			});
	},
});

export default commentsSlice.reducer;
