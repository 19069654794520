import React from 'react';
import {Drawer} from '@mui/material';

const DrawerModal = ({children, open, handleClose}) => {
	return (
		<>
			<Drawer
				open={open}
				anchor='bottom'
				onClose={handleClose}
			>
				{children}
			</Drawer>
		</>
	);
};

export default DrawerModal;
