import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import Save from '@mui/icons-material/Save'; // Import Save icon
import Send from '@mui/icons-material/Send'; // Import Send icon
import LinearProgress from '@mui/material/LinearProgress';

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchCalibrationStatus,
  fetchPeopleUnderVP,
  setSelectedPerson,
  updateCalibrationData,
} from '../../redux/userSlice';

import CalibrationFeedback from './CalibrationFeedback';
const ManagerFeedback = () => {
  const dispatch = useDispatch();
  const selectedPerson = useSelector((state) => state.user.selectedPerson);
  const user = useSelector((state) => state.PowerBi.user);

  // State for Snackbar
  const [managerPage, setManagerPage] = useState(0);
  const [managerRowsPerPage, setManagerRowsPerPage] = useState(5); // Default to show 5 rows
  const [compensationPage, setCompensationPage] = useState(0);
  const [compensationRowsPerPage, setCompensationRowsPerPage] = useState(4); // Default to show 4 rows
  const [openRevenueUtilization, setOpenRevenueUtilization] = useState(false);
  const [openRating, setOpenRating] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [calibrationStatus, setCalibrationStatus] = useState('');

  // Directly store field values
  const [finalRating, setFinalRating] = useState('');
  const [finalPromotion, setFinalPromotion] = useState('');
  const [finalIncrement, setFinalIncrement] = useState('');
  const [finalIncrementnew, setFinalIncrementnew] = useState('');
  const [finalBonus, setFinalBonus] = useState('');
  const [finalBonusnew, setFinalBonusnew] = useState('');
  const [buHeadFeedback, setBuHeadFeedback] = useState('');
  const [cpFeedback, setCpFeedback] = useState('');
  const [rvfeedback, setrvfeedback] = useState('');
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const PMS_ROLE = user?.PMS_ACCESS;
  const {
    vpNames,
    taggingOptions,
    cpNames,
    peopleUnderVP,
    selectedVP,
    selectedTagging,
    selectedCP,

    calibrationStatuses,
    selectedCalibrationStatus,
  } = useSelector((state) => state.user);

  const [isShown, setIsShown] = useState(() => {
    // Check localStorage on initial load
    return localStorage.getItem('toggleStatus') === 'show';
  });
  const [showIncrement, setShowIncrement] = useState(true);
  const [showBonus, setShowBonus] = useState(true);
  const currentYear = new Date().getFullYear();
  const currentYearLabel = currentYear - 1; // e.g., 2024
  const previousYearLabel = currentYear - 2; // e.g., 2023
  const yearMinusTwoLabel = currentYear - 3; // e.g., 2022

  const toggleBonusFields = () => {
    setShowBonus((prev) => !prev);
    // Ensure we enable the edit mode for the new field
    setEditMode((prev) => ({
      ...prev,
      finalBonus: !prev.finalBonus,
      finalBonusnew: !prev.finalBonusnew,
    }));
  };

  // Update localStorage whenever the toggle state changes
  useEffect(() => {
    setIsShown(localStorage.getItem('toggleStatus') === 'show');
  }, [localStorage.getItem('toggleStatus')]);
  // Update fields when selectedPerson changes
  useEffect(() => {
    if (selectedPerson) {
      setFinalRating(
        selectedPerson['Final Rating'] !== 'N/A'
          ? selectedPerson['Final Rating']
          : ''
      );
      setFinalPromotion(
        selectedPerson['Final Promotion'] !== 'N/A'
          ? selectedPerson['Final Promotion']
          : ''
      );
      setFinalIncrement(selectedPerson['Final Increment in %'] || '');
      setFinalIncrementnew(selectedPerson['Final Increment in #'] || '');
      setFinalBonus(selectedPerson['Final Bonus in %'] || '');
      setFinalBonusnew(selectedPerson['Final Bonus in #'] || '');
      setBuHeadFeedback(selectedPerson['BU Head Feedback'] || '');
      setCpFeedback(selectedPerson['CP Feedback'] || '');
      setrvfeedback(selectedPerson['Reviewer Feedback'] || '');
    }
  }, [selectedPerson]);
  useEffect(() => {
    if (selectedPerson) {
      setCalibrationStatus(
        selectedPerson['Calibration Status'] || 'Not Started'
      );
    }
  }, [selectedPerson]);

  useEffect(() => {
    if (
      selectedVP &&
      selectedTagging &&
      selectedCP &&
      selectedCalibrationStatus
    ) {
      dispatch(
        fetchPeopleUnderVP({
          vpName: selectedVP,
          tagging: selectedTagging,
          cpName: selectedCP,
          calibrationStatus: selectedCalibrationStatus,
        })
      ).finally(() => {});
    }
  }, [
    selectedVP,
    selectedTagging,
    selectedCP,
    selectedCalibrationStatus,
    dispatch,
  ]);
  const handleSave = async () => {
    if (!selectedPerson) return; // Early exit if no person selected

    const employeeId = selectedPerson['Emp ID'];
    const previousStatus = selectedPerson['Calibration Status']; // Capture the previous status

    const updatedData = {
      'Final Rating': finalRating || 0,
      'Final Promotion': finalPromotion,
      'Final Increment in %': finalIncrement || 0,
      'Final Increment in #': finalIncrementnew || 0,
      'Final Bonus in %': finalBonus || 0,
      'Final Bonus in #': finalBonusnew || 0,
      'BU Head Feedback': buHeadFeedback,
      'CP Feedback': cpFeedback,
      'Reviewer Feedback': rvfeedback,
      'Calibration Status': 'Saved as Draft',
    };

    try {
      setLoading(true); // Show loading indicator
      await dispatch(updateCalibrationData({employeeId, data: updatedData}));

      // Update the selected person with the new data
      const updatedPerson = {...selectedPerson, ...updatedData};
      dispatch(setSelectedPerson(updatedPerson));
      dispatch(fetchCalibrationStatus());

      // Refetch data to update the list
      await dispatch(
        fetchPeopleUnderVP({
          vpName: selectedVP,
          tagging: selectedTagging,
          cpName: selectedCP,
          calibrationStatus: selectedCalibrationStatus,
        })
      );

      // Navigate to the next person only if the previous status was 'Not Started'
      if (previousStatus === 'Not Started') {
        const currentIndex = peopleUnderVP.findIndex(
          (person) => person['Emp ID'] === employeeId
        );
        const nextPerson = peopleUnderVP[currentIndex + 1];

        // Set next person or null if at the end of the list
        dispatch(setSelectedPerson(nextPerson || null));
      }
    } catch (error) {
      console.error('Error updating calibration data:', error);
      alert('There was an error updating the data. Please try again.');
    } finally {
      setLoading(false); // Hide loading indicator
      setOpenSnackbar(true);
    }
  };

  const toggleIncrementFields = () => {
    setShowIncrement((prev) => !prev);
    // Ensure we enable the edit mode for the new field
    setEditMode((prev) => ({
      ...prev,
      finalIncrement: !prev.finalIncrement,
      finalIncrementnew: !prev.finalIncrementnew,
    }));
  };

  const handleSubmit = async () => {
    if (!selectedPerson) return;

    try {
      setLoading(true);
      const employeeId = selectedPerson['Emp ID'];
      const updatedData = {
        'Final Rating': finalRating || 0,
        'Final Promotion': finalPromotion,
        'Final Increment in %': finalIncrement || 0,
        'Final Increment in #': finalIncrementnew || 0,
        'Final Bonus in %': finalBonus || 0,
        'Final Bonus in #': finalBonusnew || 0,
        'BU Head Feedback': buHeadFeedback,
        'CP Feedback': cpFeedback,
        'Reviewer Feedback': rvfeedback,
        'Calibration Status': 'Completed', // Update the calibration status to 'Completed'
      };

      // Perform update and refetch operations in sequence
      await dispatch(updateCalibrationData({employeeId, data: updatedData}));

      dispatch(fetchCalibrationStatus());

      // Refetch data with updated filters
      await dispatch(
        fetchPeopleUnderVP({
          vpName: selectedVP,
          tagging: selectedTagging,
          cpName: selectedCP,
          calibrationStatus: selectedCalibrationStatus,
        })
      );

      // Handle next person selection
      const currentIndex = peopleUnderVP.findIndex(
        (person) => person['Emp ID'] === employeeId
      );
      const nextPerson = peopleUnderVP[currentIndex + 1];
      dispatch(setSelectedPerson(nextPerson || null));
    } catch (error) {
      console.error('Error updating calibration data:', error);
      alert('There was an error updating the data. Please try again.');
    } finally {
      setLoading(false);
      setOpenSnackbar(true); // Show a snackbar notification
    }
  };

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const handleConfirmSubmit = () => {
    handleSubmit(); // Call your existing submit function
    handleCloseConfirmModal(); // Close the modal
  };

  useEffect(() => {
    if (peopleUnderVP && selectedPerson) {
      const updatedPerson = peopleUnderVP.find(
        (person) => person['Display Name'] === selectedPerson['Display Name']
      );
      if (updatedPerson) {
        dispatch(setSelectedPerson(updatedPerson));
      }
    }
  }, [peopleUnderVP, selectedPerson, dispatch]);

  const [editMode, setEditMode] = useState({
    finalRating: false,
    finalPromotion: false,
    finalIncrement: false,
    finalIncrementnew: false,
    finalBonusnew: false,
    finalBonus: false,
  });

  const compensationColumns = [
    {
      label: 'Final Rating',
      value: (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Select
            value={selectedPerson ? finalRating : ''}
            onChange={(e) => selectedPerson && setFinalRating(e.target.value)} // Set value only if selectedPerson is not null
            disabled={
              !editMode.finalRating ||
              (calibrationStatus === 'Completed' &&
                !(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee')) ||
              !selectedPerson
            }
            variant='outlined'
            size='small'
            sx={{
              bgcolor: '#FFFFFF',
              borderRadius: '4px',
              width: '150px',
              height: '40px',
              marginRight: '8px',
              textAlign: 'center', // Center-align the text in the input field
            }}
          >
            <MenuItem value='0'>0</MenuItem>
            <MenuItem value='1'>1</MenuItem>
            <MenuItem value='1.5'>1.5</MenuItem>
            <MenuItem value='2'>2</MenuItem>
            <MenuItem value='2.5'>2.5</MenuItem>
            <MenuItem value='3'>3</MenuItem>
            <MenuItem value='3.5'>3.5</MenuItem>
            <MenuItem value='4'>4</MenuItem>
            <MenuItem value='4.5'>4.5</MenuItem>
            <MenuItem value='5'>5</MenuItem>
          </Select>
          {(calibrationStatus !== 'Completed' ||
            PMS_ROLE === 'hr' ||
            PMS_ROLE === 'review_committee') &&
            selectedPerson && (
              <IconButton
                onClick={() =>
                  setEditMode((prev) => ({
                    ...prev,
                    finalRating: !prev.finalRating,
                  }))
                }
              >
                <EditIcon />
              </IconButton>
            )}
        </div>
      ),
    },
    {
      label: 'Final Promotion',
      value: (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Select
            value={selectedPerson ? finalPromotion : ''}
            onChange={(e) =>
              selectedPerson && setFinalPromotion(e.target.value)
            }
            disabled={
              !editMode.finalPromotion ||
              (calibrationStatus === 'Completed' &&
                !(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee')) ||
              !selectedPerson
            }
            sx={{
              bgcolor: '#FFFFFF',
              borderRadius: '4px',
              width: '150px',
              height: '40px',
              marginRight: '8px',
              textAlign: 'center', // Center-align the text in the input field
            }}
          >
            <MenuItem value='Yes'>Yes</MenuItem>
            <MenuItem value='No'>No</MenuItem>
            <MenuItem value='Undecided'>Undecided</MenuItem>
          </Select>
          {(calibrationStatus !== 'Completed' ||
            PMS_ROLE === 'hr' ||
            PMS_ROLE === 'review_committee') &&
            selectedPerson && (
              <IconButton
                onClick={() =>
                  setEditMode((prev) => ({
                    ...prev,
                    finalPromotion: !prev.finalPromotion,
                  }))
                }
              >
                <EditIcon />
              </IconButton>
            )}
        </div>
      ),
    },
    {
      label: showIncrement
        ? 'Recommended Increment %'
        : 'Recommended Increment #',
      value: (
        <div style={{display: 'flex', alignItems: 'center'}}>
          {showIncrement ? (
            <TextField
              variant='outlined'
              size='small'
              type='number'
              value={selectedPerson ? finalIncrement : ''}
              onChange={(e) =>
                selectedPerson && setFinalIncrement(e.target.value)
              }
              disabled={
                !editMode.finalIncrement ||
                (calibrationStatus === 'Completed' &&
                  !(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee')) ||
                !selectedPerson
              }
              sx={{
                bgcolor: '#FFFFFF',
                borderRadius: '4px',
                width: '150px',
                height: '40px',
                marginRight: '8px',
              }}
              inputProps={{
                style: {
                  textAlign: 'center', // Center-align the text in the input field
                },
              }}
            />
          ) : (
            <TextField
              variant='outlined'
              size='small'
              type='number'
              value={selectedPerson ? finalIncrementnew : ''}
              onChange={(e) =>
                selectedPerson && setFinalIncrementnew(e.target.value || 0)
              }
              disabled={
                !editMode.finalIncrementnew ||
                (calibrationStatus === 'Completed' &&
                  !(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee')) ||
                !selectedPerson
              }
              sx={{
                bgcolor: '#FFFFFF',
                borderRadius: '4px',
                width: '150px',
                height: '40px',
                marginRight: '8px',
              }}
              inputProps={{
                style: {
                  textAlign: 'center', // Center-align the text in the input field
                },
              }}
            />
          )}
          {(calibrationStatus !== 'Completed' ||
            PMS_ROLE === 'hr' ||
            PMS_ROLE === 'review_committee') &&
            selectedPerson && (
              <IconButton
                onClick={() =>
                  setEditMode((prev) => ({
                    ...prev,
                    finalIncrement: !prev.finalIncrement,
                    finalIncrementnew: !prev.finalIncrementnew, // Toggle edit mode for both
                  }))
                }
              >
                <EditIcon />
              </IconButton>
            )}
          <IconButton onClick={toggleIncrementFields}>
            {showIncrement ? <ArrowForwardIcon /> : <ArrowBackIcon />}
          </IconButton>
        </div>
      ),
    },
    {
      label: showBonus ? 'Recommended Bonus %' : 'Recommended Bonus #',
      value: (
        <div style={{display: 'flex', alignItems: 'center'}}>
          {showBonus ? (
            <TextField
              variant='outlined'
              size='small'
              type='number'
              value={selectedPerson ? finalBonus : ''}
              onChange={(e) => selectedPerson && setFinalBonus(e.target.value)}
              disabled={
                !editMode.finalBonus ||
                (calibrationStatus === 'Completed' &&
                  !(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee')) ||
                !selectedPerson
              }
              sx={{
                bgcolor: '#FFFFFF',
                borderRadius: '4px',
                width: '150px',
                height: '40px',
                marginRight: '8px',
                // Center-align the text in the input field
              }}
              inputProps={{
                style: {
                  textAlign: 'center', // Center-align the text in the input field
                },
              }}
            />
          ) : (
            <TextField
              variant='outlined'
              size='small'
              type='number'
              value={selectedPerson ? finalBonusnew : ''}
              onChange={(e) =>
                selectedPerson && setFinalBonusnew(e.target.value)
              }
              disabled={
                !editMode.finalBonusnew ||
                (calibrationStatus === 'Completed' &&
                  !(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee')) ||
                !selectedPerson
              }
              sx={{
                bgcolor: '#FFFFFF',
                borderRadius: '4px',
                width: '150px',
                height: '40px',
                marginRight: '8px',
              }}
              inputProps={{
                style: {
                  textAlign: 'center', // Center-align the text in the input field
                },
              }}
            />
          )}
          {(calibrationStatus !== 'Completed' ||
            PMS_ROLE === 'hr' ||
            PMS_ROLE === 'review_committee') &&
            selectedPerson && (
              <IconButton
                onClick={() =>
                  setEditMode((prev) => ({
                    ...prev,
                    finalBonus: !prev.finalBonus,
                    finalBonusnew: !prev.finalBonusnew, // Toggle edit mode for both
                  }))
                }
              >
                <EditIcon />
              </IconButton>
            )}

          <IconButton onClick={toggleBonusFields}>
            {showBonus ? <ArrowForwardIcon /> : <ArrowBackIcon />}
          </IconButton>
        </div>
      ),
    },
    {
      label: 'BU Head Feedback',
      value: (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Tooltip
            title={selectedPerson ? buHeadFeedback || '' : ''}
            placement='top'
            arrow
          >
            <TextField
              value={selectedPerson ? buHeadFeedback : ''}
              onChange={(e) =>
                selectedPerson && setBuHeadFeedback(e.target.value)
              }
              multiline
              rows={3}
              disabled={
                !editMode.buHeadFeedback ||
                (calibrationStatus === 'Completed' &&
                  !(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee')) ||
                !selectedPerson
              }
              sx={{
                width: '300px',
                maxHeight: 'auto',
                overflow: 'auto',
                bgcolor: '#FFFFFF',
                borderRadius: '4px',
                marginRight: '8px',
                '&': {
                  zoom: 0.9, // Sets the zoom level to 90%
                },
              }}
              InputProps={{
                style: {fontSize: '13px'}, // Decreased font size for the input text
              }}
            />
          </Tooltip>

          {(calibrationStatus !== 'Completed' ||
            PMS_ROLE === 'hr' ||
            PMS_ROLE === 'review_committee') &&
            selectedPerson && (
              <IconButton
                onClick={() =>
                  setEditMode((prev) => ({
                    ...prev,
                    buHeadFeedback: !prev.buHeadFeedback,
                  }))
                }
              >
                <EditIcon />
              </IconButton>
            )}
        </div>
      ),
    },
    {
      label: 'CP Feedback',
      value: (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Tooltip
            title={selectedPerson ? cpFeedback || '' : ''}
            placement='top'
            arrow
          >
            <TextField
              value={selectedPerson ? cpFeedback : ''}
              onChange={(e) => selectedPerson && setCpFeedback(e.target.value)}
              multiline
              rows={3}
              disabled={
                !editMode.cpFeedback ||
                (calibrationStatus === 'Completed' &&
                  !(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee')) ||
                !selectedPerson
              }
              sx={{
                width: '300px',
                maxHeight: 'auto',
                overflow: 'auto',
                bgcolor: '#FFFFFF',
                borderRadius: '4px',
                marginRight: '8px',
                '&': {
                  zoom: 0.9, // Sets the zoom level to 90%
                },
              }}
              InputProps={{
                style: {fontSize: '13px'}, // Decreased font size for the input text
              }}
            />
          </Tooltip>
          {(calibrationStatus !== 'Completed' ||
            PMS_ROLE === 'hr' ||
            PMS_ROLE === 'review_committee') &&
            selectedPerson && (
              <IconButton
                onClick={() =>
                  setEditMode((prev) => ({
                    ...prev,
                    cpFeedback: !prev.cpFeedback,
                  }))
                }
              >
                <EditIcon />
              </IconButton>
            )}
        </div>
      ),
    },
    {
      label: 'Review Committee Feedback',
      value: (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Tooltip
            title={selectedPerson ? rvfeedback || '' : ''}
            placement='top'
            arrow
          >
            <TextField
              value={selectedPerson ? rvfeedback : ''}
              onChange={(e) => selectedPerson && setrvfeedback(e.target.value)}
              multiline
              rows={3}
              disabled={
                !editMode.rvfeedback ||
                (calibrationStatus === 'Completed' &&
                  !(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee')) ||
                !selectedPerson
              }
              sx={{
                width: '300px',
                maxHeight: 'auto',
                overflow: 'auto',
                bgcolor: '#FFFFFF',
                borderRadius: '4px',
                marginRight: '8px',
                '&': {
                  zoom: 0.9, // Sets the zoom level to 90%
                },
              }}
              InputProps={{
                style: {fontSize: '13px'}, // Decreased font size for the input text
              }}
            />
          </Tooltip>
          {(PMS_ROLE === 'hr' || PMS_ROLE === 'review_committee') &&
            (calibrationStatus !== 'Completed' ||
              PMS_ROLE === 'hr' ||
              PMS_ROLE === 'review_committee') &&
            selectedPerson && (
              <IconButton
                onClick={() =>
                  setEditMode((prev) => ({
                    ...prev,
                    rvfeedback: !prev.rvfeedback,
                  }))
                }
              >
                <EditIcon />
              </IconButton>
            )}
        </div>
      ),
    },
  ];

  const handleRevenueUtilizationToggle = () => {
    setOpenRevenueUtilization((prev) => !prev);
  };

  // Define the feedback columns dynamically
  const feedbackColumns = [
    {
      label: 'Promotion Eligibility and Recommendation',
      value: selectedPerson
        ? selectedPerson['Recommendation for Promotion'] || ''
        : '',
    },
    {
      label: `Manager's Performance Evaluation (1: Low; 5: High)`,
      value: selectedPerson
        ? selectedPerson['Overall Performance Feedback - Rating'] || ' '
        : ' ',
    },
    {
      label: 'Perceived Attrition Risk (1: Low; 5: High)',
      value: selectedPerson ? selectedPerson['Attrition Risk'] || ' ' : ' ',
    },
    {
      label: 'Criticality of Assigned Projects (1: Low; 5: High)',
      value: selectedPerson ? selectedPerson['Project Criticality'] || '' : '',
    },
    {
      label: `Career Potential and Aspiration`,
      value: selectedPerson ? selectedPerson['Aspiration'] || '' : '',
    },

    {
      label: 'Final Comments',
      value: selectedPerson ? selectedPerson['Final_Comments'] || '' : '',
    },
    {
      label: 'Compensation Recommendation',
      value: selectedPerson
        ? selectedPerson['Compensation Recommendation'] || ''
        : '',
    },
  ];

  useEffect(() => {
    if (!selectedPerson) {
      setManagerPage(0);
      setCompensationPage(0);
      setOpenRevenueUtilization(false);
      setOpenRating(false);
    }
  }, [selectedPerson]);

  const renderValueWithTooltip = (value) => {
    return (
      <Tooltip title={value} arrow>
        <Typography
          variant='body1'
          sx={{
            padding: '4px 10px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '200px',
            display: 'block',
          }}
        >
          {value.length > 30 ? value.slice(0, 30) + '...' : value}
        </Typography>
      </Tooltip>
    );
  };

  const renderLabelWithTooltip = (label) => {
    return (
      <Tooltip title={label} arrow>
        <Typography
          variant='body2'
          align='left'
          noWrap
          color='textSecondary'
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '150px',
            display: 'block',
          }}
        >
          {label.length > 30 ? label.slice(0, 30) + '...' : label}
        </Typography>
      </Tooltip>
    );
  };

  // Manager Feedback Page Change Handler
  const handleManagerChangePage = (event, newPage) => {
    setManagerPage(newPage);
    setOpenRevenueUtilization(false);
    setOpenRating(false);
    if (newPage !== 0) {
      setOpenRevenueUtilization(false); // Collapse when changing to a page other than the first page
      setOpenRating(false);
    }
  };

  const [slicedData, setSlicedData] = useState([]);

  useEffect(() => {
    const startIndex = managerPage * managerRowsPerPage;
    const endIndex = startIndex + managerRowsPerPage;
    const slicedData = feedbackColumns.slice(startIndex, endIndex);
    setSlicedData(slicedData);
  }, [managerPage, managerRowsPerPage, feedbackColumns]);

  // Compensation Page Change Handler
  const handleCompensationChangePage = (event, newPage) => {
    setCompensationPage(newPage);
  };

  const handleCompensationChangeRowsPerPage = (event) => {
    setCompensationRowsPerPage(parseInt(event.target.value, 10));
    setCompensationPage(0);
  };

  // Update the Grid container to be responsive
  return (
    <Grid
      container
      spacing={2} // Reduced spacing
      sx={{
        height: '100%',
        padding: {xs: 1, sm: 2, md: 3},
        mt: -2.0,
        mb: -6,
      }} // Reduced padding
    >
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Paper
          elevation={2}
          sx={{
            padding: 2,
            borderRadius: 2,
            flexGrow: 1,
          }}
        >
          <Typography
            variant='h5'
            gutterBottom
            sx={{
              color: '#F7901D',
              fontSize: {xs: '1.1rem', sm: '1.4rem'},
              fontWeight: 500,
              fontFamily: '"Nanum Myeongjo", serif',
              textAlign: 'center', // Center text
            }}
          >
            Manager Feedback
          </Typography>

          <TableContainer
            component={Paper}
            sx={{
              borderRadius: 2,
              overflow: 'auto',
              maxHeight: '230px',
            }}
          >
            <Table size='small'>
              <TableBody>
                {slicedData.map((item) => {
                  // Check if the label contains "(1: Low; 5: High)"
                  const labelContainsPattern = /(1: Low; 5: High)/.test(
                    item.label
                  );

                  return (
                    <TableRow key={item.label}>
                      <TableCell
                        sx={{
                          height: '43px',
                          padding: {xs: '4px', sm: '8px'},
                          color: 'black',
                        }}
                      >
                        <Typography
                          color='black'
                          variant='body2'
                          sx={{
                            fontSize: '0.842rem',
                            fontWeight: 'bold', // Bold default for labels
                          }}
                        >
                          {item.label ===
                          'Career Potential and Aspiration (1: Low; 3: High)' ? (
                            <Typography
                              variant='body2'
                              sx={{
                                fontSize: '0.842rem',
                                // Bold default for labels
                              }}
                            >
                              <strong>Career Potential and Aspiration</strong>{' '}
                              (1: Low; 3: High)
                            </Typography>
                          ) : (
                            <>
                              {/* Render other labels normally */}
                              {labelContainsPattern
                                ? item.label.split('(1: Low; 5: High)')[0] // Before "(1: Low; 5: High)"
                                : item.label}
                              {labelContainsPattern && (
                                <span style={{fontWeight: 'normal'}}>
                                  (1: Low; 5: High)
                                </span>
                              )}
                              {labelContainsPattern &&
                                item.label.split('(1: Low; 5: High)')[1]}
                            </>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          height: '43px',
                          padding: {xs: '4px', sm: '8px'},
                          textAlign:
                            item.value === selectedPerson?.Final_Comments ||
                            item.value ===
                              selectedPerson?.['Compensation Recommendation']
                              ? 'left'
                              : 'center', // Conditional alignment
                          // Conditional alignment
                        }}
                      >
                        {item.value}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10]} // Options for rows per page
            component='div'
            count={feedbackColumns.length} // Total number of rows in the feedback table
            rowsPerPage={managerRowsPerPage}
            page={managerPage}
            onPageChange={handleManagerChangePage}
            onRowsPerPageChange={(event) => {
              setManagerRowsPerPage(parseInt(event.target.value, 10));
              setManagerPage(0);
            }}
          />
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{display: 'flex', flexDirection: 'column', mt: 0}}
      >
        <Paper elevation={2} sx={{padding: 2, borderRadius: 2, flexGrow: 1}}>
          <Typography
            variant='h5'
            gutterBottom
            sx={{
              color: '#F7901D',
              fontSize: {xs: '1.1rem', sm: '1.4rem'},
              fontWeight: 500,
              fontFamily: '"Nanum Myeongjo", serif',
            }}
          >
            Calibration Feedback
          </Typography>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: 2,
              overflowY: 'scroll', // Enables scrolling
              maxHeight: '230px', // Constrains the height for scroll
              '&::-webkit-scrollbar': {display: 'none'}, // Hides the scrollbar in Webkit-based browsers
              msOverflowStyle: 'none', // Hides scrollbar for Internet Explorer and Edge
              scrollbarwidth: 'none', // Hides scrollbar for Firefox
            }}
          >
            <Table size='small'>
              <TableBody>
                {compensationColumns
                  .slice(
                    compensationPage * compensationRowsPerPage,
                    compensationPage * compensationRowsPerPage +
                      compensationRowsPerPage
                  )
                  .map((column, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          height: '50px',

                          padding: {xs: '4px', sm: '8px'}, // Reduced padding
                          fontSize: '0.840rem',
                          fontWeight: 550,
                        }}
                      >
                        {column.label}
                      </TableCell>
                      <TableCell
                        sx={{
                          height: '50px',
                          padding: {xs: '3px', sm: '7px'}, // Reduced padding
                        }}
                      >
                        {column.value}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Compensation Table Pagination */}
          <TablePagination
            rowsPerPageOptions={[4, 8]} // Options for rows per page
            component='div'
            count={compensationColumns.length} // Total number of rows in the compensation table
            rowsPerPage={compensationRowsPerPage}
            page={compensationPage}
            onPageChange={handleCompensationChangePage}
            onRowsPerPageChange={handleCompensationChangeRowsPerPage}
          />
        </Paper>
      </Grid>

      {/* Revenue Utilization Grid */}
      <Grid
        item
        xs={12}
        sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      >
        <Paper elevation={2} sx={{padding: 2, borderRadius: 2, width: '100%'}}>
          <Grid
            container
            spacing={2}
            sx={{overflowX: 'auto', alignItems: 'center'}}
          >
            {/* Header with the title and arrow button */}
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant='h6'
                sx={{
                  color: '#F7901D',
                  fontSize: {xs: '1.1rem', sm: '1.4rem'},
                  fontWeight: 500,
                  fontFamily: '"Nanum Myeongjo", serif',
                }}
              >
                Revenue Utilization
              </Typography>
              <IconButton
                onClick={() =>
                  setOpenRevenueUtilization(!openRevenueUtilization)
                }
              >
                {openRevenueUtilization ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
              </IconButton>
            </Grid>

            {/* Collapsible content */}
            {openRevenueUtilization && (
              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  sx={{
                    borderRadius: 2,
                    overflow: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '200px',
                            textAlign: 'center',
                            fontWeight: 'bold', // Make header text bold
                            wordWrap: 'break-word',
                            maxWidth: '200px',
                          }}
                        >
                          Current Review Year Revenue
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '200px',
                            textAlign: 'center',
                            fontWeight: 'bold', // Make header text bold
                            wordWrap: 'break-word',
                            maxWidth: '200px',
                          }}
                        >
                          Total Review Months
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '200px',
                            textAlign: 'center',
                            fontWeight: 'bold', // Make header text bold
                            wordWrap: 'break-word',
                            maxWidth: '200px',
                          }}
                        >
                          Billed Utilization
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '210px',
                            textAlign: 'center',
                            fontWeight: 'bold', // Make header text bold
                            wordWrap: 'break-word',
                            maxWidth: '200px',
                          }}
                        >
                          Non Bench Utilization
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '200px',
                            textAlign: 'center',
                            wordWrap: 'break-word',
                            maxWidth: '200px',
                          }}
                        >
                          <Typography
                            variant='body2'
                            gutterBottom
                            sx={{
                              textAlign: 'center',
                              fontSize: '0.8rem',
                              '@media (max-width: 600px)': {
                                fontSize: '0.75rem',
                              },
                            }}
                          >
                            {selectedPerson
                              ? selectedPerson[
                                  'Current_Year_Revenue (Jan - Dec)'
                                ] || ''
                              : ''}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '200px',
                            textAlign: 'center',
                            wordWrap: 'break-word',
                            maxWidth: '200px',
                          }}
                        >
                          <Typography
                            variant='body2'
                            gutterBottom
                            sx={{
                              textAlign: 'center',
                              fontSize: '0.875rem',
                              '@media (max-width: 600px)': {
                                fontSize: '0.75rem',
                              },
                            }}
                          >
                            {selectedPerson
                              ? selectedPerson['Total Months (Jan - Dec)'] || ''
                              : ''}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '200px',
                            textAlign: 'center',
                            wordWrap: 'break-word',
                            maxWidth: '200px',
                          }}
                        >
                          <Typography
                            variant='body2'
                            gutterBottom
                            sx={{
                              textAlign: 'center',
                              fontSize: '0.875rem',
                              '@media (max-width: 600px)': {
                                fontSize: '0.75rem',
                              },
                            }}
                          >
                            {selectedPerson
                              ? selectedPerson['Billed Util (Jan - Dec)'] || ''
                              : ''}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '200px',
                            textAlign: 'center',
                            wordWrap: 'break-word',
                            maxWidth: '200px',
                          }}
                        >
                          <Typography
                            variant='body2'
                            gutterBottom
                            sx={{
                              textAlign: 'center',
                              fontSize: '0.875rem',
                              '@media (max-width: 600px)': {
                                fontSize: '0.75rem',
                              },
                            }}
                          >
                            {selectedPerson
                              ? selectedPerson['Non Bench Util (Jan - Dec)'] ||
                                ''
                              : ''}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      >
        <Paper elevation={2} sx={{padding: 2, borderRadius: 2, width: '100%'}}>
          <Grid
            container
            spacing={2}
            sx={{overflowX: 'auto', alignItems: 'center'}}
          >
            {/* Header with the title and arrow button */}
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant='h6'
                sx={{
                  color: '#F7901D',
                  fontSize: {xs: '1.1rem', sm: '1.4rem'},
                  fontWeight: 500,
                  fontFamily: '"Nanum Myeongjo", serif',
                }}
              >
                Performance History
              </Typography>
              <IconButton onClick={() => setOpenRating(!openRating)}>
                {openRating ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Grid>

            {/* Collapsible content */}
            {openRating && (
              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  sx={{
                    borderRadius: 2,
                    overflow: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '200px',
                            textAlign: 'center',
                            fontWeight: 'bold', // Bold for header text
                          }}
                        >
                          {currentYearLabel} Review Rating
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '200px',
                            textAlign: 'center',
                            fontWeight: 'bold', // Bold for header text
                          }}
                        >
                          {previousYearLabel} Review Rating
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '200px',
                            textAlign: 'center',
                            fontWeight: 'bold', // Bold for header text
                          }}
                        >
                          {yearMinusTwoLabel} Review Rating
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '200px',
                            textAlign: 'center',
                            fontWeight: 'bold', // Bold for header text
                          }}
                        >
                          Last Promotion Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '200px',
                            textAlign: 'center', // Center align text
                          }}
                        >
                          <Typography
                            variant='body2'
                            sx={{textAlign: 'center'}}
                          >
                            {selectedPerson?.['Current_yr_Rating'] === 0 ||
                            selectedPerson?.['Current_yr_Rating'] == null
                              ? ' '
                              : selectedPerson?.['Current_yr_Rating'] ?? ' '}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '200px',
                            textAlign: 'center', // Center align text
                          }}
                        >
                          <Typography
                            variant='body2'
                            sx={{textAlign: 'center'}}
                          >
                            {selectedPerson?.['Current-1_yr_Rating'] === 0 ||
                            selectedPerson?.['Current-1_yr_Rating'] == null
                              ? ' '
                              : selectedPerson?.['Current-1_yr_Rating'] ?? ' '}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '200px',
                            textAlign: 'center', // Center align text
                          }}
                        >
                          <Typography
                            variant='body2'
                            sx={{textAlign: 'center'}}
                          >
                            {selectedPerson?.['Current-2_yr_Rating'] === 0 ||
                            selectedPerson?.['Current-2_yr_Rating'] == null
                              ? ' '
                              : selectedPerson?.['Current-2_yr_Rating'] ?? ' '}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: 1,
                            border: '1px solid #ddd',
                            fontSize: '0.875rem',
                            width: '200px',
                            textAlign: 'center', // Center align text
                          }}
                        >
                          <Typography
                            variant='body2'
                            sx={{textAlign: 'center'}}
                          >
                            {selectedPerson?.['Last Promoted Date'] === '0'
                              ? 'Never Promoted'
                              : selectedPerson?.['Last Promoted Date'] ??
                                'Unknown'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>

      {/* ... */}
      <Grid item xs={12}>
        {(calibrationStatus !== 'Completed' ||
          PMS_ROLE === 'hr' ||
          PMS_ROLE === 'review_committee') && (
          <Box
            sx={{
              position: 'relative', // Enables absolute positioning for the loading bar
              display: 'flex',
              flexDirection: {xs: 'column', sm: 'row'}, // Stack buttons on small screens
              justifyContent: 'space-between',
              alignItems: 'center', // Center align buttons vertically
              mb: -1, // Adjusted margin-bottom
            }}
          >
            {loading && (
              <Box
                sx={{
                  position: 'absolute', // Overlay the loading bar
                  top: 0,
                  left: 0,
                  width: '100%',
                  zIndex: 1, // Ensure it overlays the buttons
                }}
              >
                <LinearProgress />
              </Box>
            )}

            <Button
              variant='contained'
              startIcon={<Save />}
              onClick={handleSave}
              disabled={loading} // Disable button when loading
              sx={{
                marginBottom: {xs: 2, sm: 0}, // Add margin to the bottom on small screens
                backgroundColor: '#333333', // Change this to any of the shades above
                color: '#FFFFFF', // Ensure the text color contrasts well with the background
                '&:hover': {
                  backgroundColor: '#000000', // Darker shade on hover
                },
              }}
            >
              Save
            </Button>

            <Button
              variant='contained'
              endIcon={<Send />}
              onClick={handleOpenConfirmModal}
              disabled={loading} // Disable button when loading
              sx={{
                backgroundColor: '#F7901D', // Set the background color
                color: '#FFFFFF', // Ensure the text color contrasts well with the background
                '&:hover': {
                  backgroundColor: '#D6791A', // Slightly darker shade for hover effect
                },
              }}
            >
              Submit
            </Button>
          </Box>
        )}
      </Grid>

      {isShown && (
        <Grid item xs={12} sx={{mt: 0, mb: 0}}>
          {/* Adjusted margins */}
          <CalibrationFeedback />
        </Grid>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}} // Center Snackbar
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity='success'
          sx={{
            width: '100%',
            fontSize: '1.5rem', // Increased font size
            padding: '30px', // Larger padding for more prominence
            maxWidth: '500px', // Adjusted width
            textAlign: 'center', // Center-align text in the alert
            fontWeight: 'bold', // Bold text for emphasis
            backgroundColor: '#4caf50', // Custom background color for success
            color: '#FFFFFF', // White text for high contrast
            borderRadius: '8px', // Rounded corners for a modern look
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Shadow for depth
            border: '2px solid #388e3c', // Border for additional focus
          }}
        >
          Values saved successfully!
        </Alert>
      </Snackbar>
      <Dialog open={openConfirmModal} onClose={handleCloseConfirmModal}>
        <DialogTitle>Confirm Submission</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to submit? Once submitted, you cannot change
            any values.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmModal} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleConfirmSubmit} color='primary' autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ManagerFeedback;
