import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useOutletContext} from 'react-router-dom';
import {addRevenueFormData} from '../../redux/formReducer';
import revenuDataApi from '../../components/Utilities/apis/revenuDataApi';
import RevenueForm from '../../components/Form/RevenuDataForm';

const RevenuDataComponent = ({handleClose}) => {
	const user = useSelector((state) => state.PowerBi.user);
	const dispatch = useDispatch();
	const googleIdToken = useOutletContext(); // <-- access context value

	const [userForm, setUserForm] = useState({});

	useEffect(() => {
		if (Object.keys(user).length !== 0) {
			setUserForm(user);
			revenuDataApi([googleIdToken, user?.EMAILID, user?.PBI_ROLE]).then(
				(result) => {
					dispatch(addRevenueFormData(result));
				}
			);
		}
	}, [dispatch, googleIdToken, user]);
	return <RevenueForm handleClose={handleClose} />;
};

export default RevenuDataComponent;
