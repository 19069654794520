import axios from 'axios';
import config from '../../../config';

const revenuDataApi = async ([googleIdToken, userEmail, userPBIRole]) => {
	try {
		const result = await axios.get(`${config.apiURL}/getRevenueData`, {
			headers: {
				Authorization: `Bearer ${googleIdToken}`,
				userEmail,
				userPBIRole,
			},
		});
		return result?.data || {};
	} catch (err) {
		return {};
	}
};

export default revenuDataApi;
