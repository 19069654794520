import axios from 'axios';
import config from './../../../config';
const updateUser = async (props) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const res = await axios.put(`${config.apiURL}/v2/updateUser`, {
			Authorization: `Bearer ${token}`,
			props,
		});
		return res;
	} catch (err) {
		return err;
	}
};

const updateAdmin = async (email, value) => {
	const token = localStorage.getItem('googleIdToken');
	try {
		const res = await axios.put(`${config.apiURL}/v2/updateAdmin`, {
			Authorization: `Bearer ${token}`,
			email,
			value,
		});
		return res;
	} catch (err) {
		return err;
	}
};
export default updateUser;
export {updateAdmin};
