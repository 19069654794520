import React, {useState} from 'react';
import './Tabs.css'; // Import CSS file for styling
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import {Stack, Typography} from '@mui/material';
import CottageIcon from '@mui/icons-material/Cottage';

import {useNavigate} from 'react-router-dom';
import {Button} from '@mui/material';

const Tabs = ({tabs}) => {
	const [activeTab, setActiveTab] = useState(0);
	const Navigate = useNavigate();

	const handleTabClick = (index) => {
		setActiveTab(index);
	};

	return (
		<div className='tabs-container'>
			<div className='tabs'>
				<Stack
					direction='row'
					style={{
						display: 'flex',
						alignItems: 'center',
						textAlign: 'center',
						marginRight: '2rem',
						borderRadius: '0.2rem',
					}}
					className='text'
				>
					<HomeTwoToneIcon
						style={{
							cursor: 'pointer',
							textTransform: 'none',
						}}
					/>
					<Typography
						onClick={() => Navigate('/reports')}
						style={{textAlign: 'center'}}
					>
						Home
					</Typography>
				</Stack>
				{tabs.map((tab, index) => (
					<div
						key={index}
						className={index === activeTab ? 'tab active' : 'tab'}
						onClick={() => handleTabClick(index)}
					>
						{tab.title}
					</div>
				))}
			</div>
			<div className='tab-content'>{tabs[activeTab].content}</div>
		</div>
	);
};

export default Tabs;
