import Header from '../../components/Header/Header';
import {StyledEngineProvider} from '@mui/material/styles';
import classes from './AdminNew.module.css';
import Box from '@mui/material/Box';
import Tabs from '../../components/Tabs/Tabs';
import UsersDetails from './UsersDetails';
import ReportPage from '../Reports/ReportPage';
import UserDetailsReports from '../UserReports/UserDetailsReports';

const AdminNew = () => {
	return (
		<StyledEngineProvider injectFirst>
			<Box>
				<Header />
				<Box className={classes.body}>
					<Tabs
						tabs={[
							{title: 'ReportConfig', content: <UserDetailsReports />},
							{title: 'Users', content: <UsersDetails />},
							{title: 'Reports', content: <ReportPage />},
						]}
					/>
				</Box>
			</Box>
		</StyledEngineProvider>
	);
};

export default AdminNew;
